import {Socializer} from "../../util/socializer.js";
import {PushMessaging} from "../../util/pushmessaging.js";

import {SimpleTileGridRenderer} from "../simpletilegridrenderer.js";
import {Localizer} from "../../util/localizer.js";

export class ChatSocketIO extends SimpleTileGridRenderer{

    init (noAutoInit) {
        console.log("init chat socket io",noAutoInit);
        super.init();
        if (!noAutoInit) {
            this.initSocketIO();
        }
    }

    createComponents  () {
        super.createComponents();
        this.scrollTo(100000);
    }

    unload () {
        super.unload();
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    rateMessage(message,rating,retry){
        let socialUser = Socializer.getSocialUser();

        if (!socialUser){
            console.error("no social user found -> cannot rate message without logging in");
            //login request

            if (retry){
                AppInstance.activePage.getPageTemplate().showToast("error_login_failed_after_retry");
                return;
            }
            this.triggerEvent("authentication_start");
            Socializer.requestLogin((socialUser) => {
                this.triggerEvent("authentication_end",socialUser);
                if (!socialUser){
                    AppInstance.activePage.getPageTemplate().showToast("error_login_failed");
                    return;
                }
                this.rateMessage(message, rating,1);
            })
            return;
        }

        console.log("rate message",message);
        var newMessage = {};
        newMessage.mid = message.id;
        newMessage.pid = socialUser.pid;
        newMessage.image = socialUser.image;
        newMessage.un = socialUser.un;
        newMessage.matchid = this.chatIndex;
        newMessage.type = 1;
        newMessage.rating = rating;
        newMessage.style = {height:80};
        newMessage.time = Math.abs(new Date()/1000);

        this.sendVote(newMessage);
    }

    addMessage (message,retry) {

        if (!message || message == Localizer.getString("chattypehint")){
            console.error("cannot add empty message");
            return;
        }

        let socialUser = Socializer.getSocialUser();

        if (!socialUser){
            console.error("no social user found -> cannot add message without logging in");
            //login request

            if (retry){
                console.error("retrying failed login");
                AppInstance.activePage.getPageTemplate().showToast("error_login_failed_after_retry");
                return;
            }

            this.triggerEvent("authentication_start");
            Socializer.requestLogin((socialUser) => {
                this.triggerEvent("authentication_end",socialUser);
                if (!socialUser){
                    console.log("login failed");
                    AppInstance.activePage.getPageTemplate().showToast("error_login_failed");
                    return;
                }
                this.addMessage(message,1);
            })
            return;
        }

        var newMessage = {};
        newMessage.m = message;
        newMessage.temp = 1;
        newMessage.pid = socialUser.pid;
        newMessage.image = socialUser.image;
        newMessage.un = socialUser.un;
        newMessage.matchid = this.chatIndex;
        newMessage.type = 1;
        newMessage.style = {height:80};
        newMessage.delivered = false;
        newMessage.time = Math.round(new Date()/1000);

        console.log("add message",newMessage);

        var datasource = this.getDataSource();
        datasource.push(newMessage);

        console.log("Set new datasource ",datasource);
        this.setDataSource(datasource,true);
        setTimeout(() => {
            this.scrollTo(99999999);
        },50);

        this.sendMessage(message);
    }

    sendMessage (message) {
        let socialUser = Socializer.getSocialUser();

        console.log("going to send message ",FootballManiaConfig.isAndroid);

        PushMessaging.getToken((token)=>{
            var data = {};
            data.m = message;
            data.nw = FootballManiaConfig.isAndroid ? 3 : 10;
            data.gcm = token;
            data.pid = socialUser.pid;
            data.token = socialUser.token;
            data.maniatoken = socialUser.maniatoken;
            data.image = socialUser.image;
            data.un = socialUser.un;
            data.matchid = this.chatIndex;

            this.socket.emit("post_message",JSON.stringify(data), (response) => {
                console.log("message posted",response);
                if (response == -4){ // not logged in
                    console.log("not logged in or expired token");
                    // AppInstance.activePage.getPageTemplate().showToast("not_logged_in");
                   // Socializer.resetManiaToken();
                    // Socializer.requestLogout(() => {});

                    Socializer.requestLogin((socialUser) => {
                        if (!socialUser){
                            AppInstance.activePage.getPageTemplate().showToast("error_login_failed");
                            this.triggerEvent("authentication_error");
                            return;
                        }
                        this.socket.emit("post_message",JSON.stringify(data), (response) => {
                            console.log("message posted", response);
                        });
                    })
                }
            });
        });
    }

    sendVote (message) {
        let socialUser = Socializer.getSocialUser();

        PushMessaging.getToken((token)=>{
            var data = {};
            data.rating = message.rating;
            data.mid = message.mid;
            data.nw = FootballManiaConfig.isAndroid ? 3 : 10;
            data.gcm = token;
            data.pid = socialUser.pid;
            data.token = socialUser.token;
            data.maniatoken = socialUser.maniatoken;
            data.image = socialUser.image;
            data.un = socialUser.un;
            data.matchid = this.chatIndex;

            console.log("send ",data);

            this.socket.emit("post_vote",JSON.stringify(data), (response) => {
                console.log("vote posted",response);
                if (response == -4){ // not logged in
                    console.log("not logged in or expired token");
                    // AppInstance.activePage.getPageTemplate().showToast("not_logged_in");
                    // Socializer.resetManiaToken();
                    // Socializer.requestLogout(() => {});

                    Socializer.requestLogin((socialUser) => {
                        if (!socialUser){
                            AppInstance.activePage.getPageTemplate().showToast("error_login_failed");
                            this.triggerEvent("voteFailed");
                            this.triggerEvent("authentication_error");
                            return;
                        }
                        this.socket.emit("post_vote",JSON.stringify(data), (response) => {
                            console.log("message voted", response);
                        });
                    })
                }
                else if (response == -1){ // not logged in
                    console.log("already voted");
                    this.triggerEvent("voteFailed");
                }
            });
        });
    }

    adminRemoveMessage (message) {
        console.log("remove message");
        let socialUser = Socializer.getSocialUser();

        PushMessaging.getToken((token)=>{
            var data = {};
            data.mid = message.id;
            data.nw = FootballManiaConfig.isAndroid ? 3 : 10;
            data.gcm = token;
            data.pid = socialUser.pid;
            data.token = socialUser.token;
            data.maniatoken = socialUser.maniatoken;
            data.image = socialUser.image;
            data.un = socialUser.un;
            data.matchid = this.chatIndex;

            console.log("send ",data);

            this.socket.emit("admin_remove_message",JSON.stringify(data), (response) => {
                console.log("vote posted",response);
                if (response == -4){ // not logged in
                    console.log("not logged in or expired token");
                    AppInstance.activePage.getPageTemplate().showToast("error: not logged in or expired token");
                    this.triggerEvent("authentication_error");

                }
                else if (response == -5){ // not logged in
                    console.log("no admin rights");
                    AppInstance.activePage.getPageTemplate().showToast("error: not authorized for this action");
                }
                else if (response == -1){ // not logged in
                    AppInstance.activePage.getPageTemplate().showToast("error: unexpected error. please contact support");
                }
                else{
                    console.log("success");
                }
                this.triggerEvent("adminActionFailed");
            });
        });

    }

    adminBanUser (message,duration) {
        console.log("ban user");
        let socialUser = Socializer.getSocialUser();

        console.log("ban user",message,duration);

        PushMessaging.getToken((token)=>{
            var data = {};
            data.mid = message.id;
            data.nw = FootballManiaConfig.isAndroid ? 3 : 10;
            data.gcm = token;
            data.pid = socialUser.pid;
            data.token = socialUser.token;
            data.maniatoken = socialUser.maniatoken;
            data.image = socialUser.image;
            data.un = socialUser.un;
            data.matchid = this.chatIndex;
            // data.userToBanUid =
            // data.userToBanId =
            // data.userToBanNw =
            // data.banDuration =

            console.log("send ",data);

            this.socket.emit("admin_remove_message",JSON.stringify(data), (response) => {
                console.log("vote posted",response);
                if (response == -4){ // not logged in
                    console.log("not logged in or expired token");
                    AppInstance.activePage.getPageTemplate().showToast("error: not logged in or expired token");
                    this.triggerEvent("authentication_error");

                }
                else if (response == -5){ // not logged in
                    console.log("no admin rights");
                    AppInstance.activePage.getPageTemplate().showToast("error: not authorized for this action");
                }
                else if (response == -1){ // not logged in
                    AppInstance.activePage.getPageTemplate().showToast("error: unexpected error. please contact support");
                    this.triggerEvent("authentication_error");
                }
                else{
                    console.log("success");
                }
                this.triggerEvent("adminActionFailed");
            });
        });
    }

    initSocketIO () {

        console.log("init socket io");
        console.trace();
        if (this.initalized) return;
        this.initialized = true;

        const socket = io("/", {
            path: "/socketioweb",
            rejectUnauthorized: false,
            reconnectionDelayMax: 10000,
            forceNew : false,
            multiplex : true,
            autoConnect: false,
            transports: ["websocket"]
        });

        this.socket = socket;

        socket.on("connect", () => {

            const engine = socket.io.engine;
            console.log(engine.transport.name); // in most cases, prints "polling"

            engine.once("upgrade", () => {
                // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
                console.log("engine upgrade => "+engine.transport.name); // in most cases, prints "websocket"
            });

            engine.on("packet", ({ type, data }) => {
                // called for each packet received
                //console.log("engine packet => "+type+" "+data);
            });

            engine.on("packetCreate", ({ type, data }) => {
                // called for each packet sent
                //console.log("engine packetCreate => "+type+" "+data);
            });

            engine.on("drain", () => {
                // called when the write buffer is drained
                //console.log("engine drain");
            });

            engine.on("close", (reason) => {
                // called when the underlying connection is closed
                //console.log("engine close => "+reason);
            });

            let socialUser = Socializer.getSocialUser();

            console.log("socket-io connected");
            socket.sendBuffer = [];
            var data = {
                matchid : this.chatIndex,
            }

            if (socialUser){
                PushMessaging.getToken(function(token){
                    data.nw = socialUser.nw;
                    data.maniatoken = socialUser.maniatoken;
                    data.gcm = token;
                    data.pid = socialUser.pid;
                    data.token = socialUser.token;
                    data.image = socialUser.image;
                    data.un = socialUser.un;

                    console.log("joinroom",data);

                    socket.emit("joinroom",JSON.stringify(data), (arg) => {
                        console.log("joinroom",data);
                    });
                });
            }
            else{
                socket.emit("joinroom",JSON.stringify(data));
            }
        });

        socket.on("incoming connection", (arg) => {
            console.log("socket-io incoming connection",arg);
        });

        socket.on("accepted", (arg) => {
            this.triggerEvent("accepted",arg);
        });

        socket.on("disconnect", (arg) => {
            console.log("socket-io disconnect",arg);
        });

        socket.on("error", (arg) => {
            console.log("socket-io error",arg);
        });

        socket.io.on("ping", () => {
            console.log("socet-io ping")
        });

        socket.io.on("reconnect", (attempt) => {
            console.log("socket-io reconnect",attempt);
        });

        socket.io.on("reconnect_attempt", (attempt) => {
            console.log("socket-io reconnect_attempt",attempt);
        });

        socket.io.on("reconnecting", (attempt) => {
            console.log("socket-io reconnecting",attempt);
        });

        socket.io.on("reconnect_error", (arg) => {
            console.log("socket-io reconnect_error",arg);
        });

        socket.on("connect_error", (arg,a) => {
            console.log("socket-io connect_error",arg);
        });

        socket.on("connect_timeout", (arg) => {
            console.log("socket-io connect_timeout",arg);
        });

        socket.on("event", (arg) => {
            console.log("socket-io event",arg);
        });

        socket.on("incoming_message", (arg) => {
            console.log("socket-io incoming_message",arg);
            var datasource = this.getDataSource();

            var newMessage = JSON.parse(arg);

            var foundExisting = null;
            for (var i = 0; i < datasource.length; i++) {
                console.log("loop ",datasource[i].id,newMessage.id,datasource[i].id == newMessage.id,datasource[i].id != null);
                if (datasource[i].id != null && datasource[i].id == newMessage.id){
                    foundExisting = datasource[i];
                    foundExisting.style = {height:80};
                    foundExisting.rating = newMessage.rating;
                    foundExisting.userVote = newMessage.userVote;
                    newMessage = foundExisting;
                    newMessage.temp = null;
                    if (newMessage.delivered === false) {
                        newMessage.delivered = true;
                    }
                    console.log("found existing message",foundExisting);
                    break;
                    ///return;
                }
            }

            if (!foundExisting){
                newMessage.type = 1;
                newMessage.style = {height:80};
                if (!newMessage.time) {
                    newMessage.time = Math.abs(newMessage.t / 1000);
                }
                datasource.push(newMessage);
                console.log("not found existing message",newMessage.t,newMessage);
            }

            console.log("new message ",newMessage);

            //return;
            for (var i = 0; i < datasource.length; i++) {
                if (datasource[i].temp == 1 && datasource[i].m == newMessage.m){
                    console.log("replace temp (added) message with incoming message");
                    datasource.splice(i,1);
                    break;
                }
            }

            console.log("Set new datasource ",datasource);
            this.setDataSource(datasource);

            if (!foundExisting) {
                setTimeout(() => {
                    this.scrollTo(99999999);
                }, 500);
            }

            this.triggerEvent("incoming_message",newMessage);

        });

        socket.on("authentication_update", (data) => {
            console.log("socket-io authentication_update",data);
            if (data.user && data.user.maniatoken){
                Socializer.setManiaToken(data.user.maniatoken);
            }
        });

        socket.on("refresh_chat", (data) => {
            console.log("socket-io refresh_chat",data);
            if (data.messages){

                for (var i = 0; i < data.messages.length; i++) {
                    data.messages[i].type = 1;
                    data.messages[i].style = {height:80};
                }
                console.log("set messages ",data.messages);
                this.setDataSource(data.messages);
                this.triggerEvent("refresh_chat");
            }
        });

        socket.open((err) => {
            if (err) {
                console.log("socket-io error",err);
            } else {
                console.log("socket-io open");
            }
        });
    }
};