export const AnimatorHome = function () {
    // Private state object
    const state = {
        version: "1.0",
        tabs: [],
        scrollerMethods: new Map(),
        touchStartMethod: null,
        touchEndMethod: null,
        collapser: null,
        tabview: null,
        ignoreEvents: false,
        ignoreEventForIndex: new Set(),
        lastListIndexScrolling: -1,
        unloaded: false,
        isTouching: false,
        reducerRatio: 0.3,
        transitionDuration: 100,
        alwaysCollapseOnBackScrolling: true,
        isCollapsed: false,
        lastRecalculateWasCollapsed: null,
        isVirtuallyCollapsed: false,
        lastScrollPositionAfterTouchStart: -1,
        lastYOffsetAfterTouchStart: 0,
        currentTranslateY: 0,
        currentActiveTab: -1,
        tempTimeout: null,
        collapserHeight: 0,
    };

    // Public callback
    this.onCollapseChanged = null;

    // Add a scroller container (tab)
    this.addScrollerContainer = (tab) => {
        if (tab) state.tabs.push(tab);
    };

    // Add collapser element
    this.addCollapser = (c) => {
        state.collapser = c;
    };

    // Add tab view
    this.addTabView = (tab) => {
        state.tabview = tab;
    };

    // Get cached collapser height
    this.getCollapserHeight = () => {
        if (!state.collapser) return 0;
        if (!state.collapserHeight) {
            state.collapserHeight = state.collapser.getDimensions().height;
        }
        return state.collapserHeight;
    };

    // Cleanup method
    this.unload = () => {
        if (state.unloaded) return;
        state.unloaded = true;

        if (state.collapser) {
            state.collapser.setCss({ transform: "translate(0px,0px)", "transition-duration": "0ms" });
        }

        state.tabs.forEach((tab) => {
            const $scrollEl = jQuery(`#${tab.getClientId()}`);
            const handler = state.scrollerMethods.get(tab.getClientId());
            if (handler) $scrollEl.off("scroll", handler);
        });
        state.scrollerMethods.clear();

        jQuery("body").off({
            touchstart: state.touchStartMethod,
            touchend: state.touchEndMethod,
        });
    };

    // Handle tab change
    this.tabChange = () => {
        if (!FootballManiaConfig?.graphics?.animations || !state.tabview) return;

        if (state.currentActiveTab === -1) {
            state.currentActiveTab = state.tabview.currentActiveTab;
        }

        const delay = 300;
        const collapserId = state.collapser.getClientId();
        const tabHolderId = state.tabview.tabHolder.getClientId();

        jQuery(`#${collapserId}, #${tabHolderId}`).css({
            transform: "translate(0px,0px)",
            "transition-duration": `${delay}ms`,
        });

        state.currentTranslateY = 0;
        state.isVirtuallyCollapsed = false;
        state.isCollapsed = false;

        setTimeout(() => this.recalculate(), delay);

        state.tabs.forEach((tab, i) => {
            if (i === state.tabview.currentActiveTab) return;

            state.ignoreEvents = true;
            state.ignoreEventForIndex.add(i);

            setTimeout(() => {
                const scrollElement = document.querySelector(`#${tab.getClientId()}`);
                const lazygrid = tab.controls?.[0]?.controls?.[0];

                if (lazygrid?.scrollToLastSelectedOrTop) {
                    lazygrid.scrollToLastSelectedOrTop();
                } else if (scrollElement) {
                    scrollElement.scrollTo({ top: 0, left: 0 });
                }

                state.ignoreEvents = false;
                state.ignoreEventForIndex.delete(i);
            }, 0);
        });

        state.currentActiveTab = state.tabview.currentActiveTab;
    };

    // Recalculate a specific tab's layout
    this.recalculateTab = (index) => {
        if (!FootballManiaConfig?.graphics?.animations || !state.tabs[index] || !state.tabview) return;

        const tab = state.tabview.tabContents?.controls[index];
        if (!tab?.controls?.[1]?.controls?.[0]) return;

        const collapserHeight = this.getCollapserHeight();
        const $scrollEl = jQuery(`#${state.tabs[index].getClientId()}`);
        if (!$scrollEl.length) return;

        const extraMargin = this.scrollerBottomMargin || 0;
        const top = collapserHeight;

        jQuery(`#${tab.getClientId()}`).css({
            top: `-${top}px`,
            height: `calc(100% + ${top - extraMargin}px)`,
        });

        jQuery(`#${tab.controls[1].controls[0].controls[0].getClientId()}`).css({
            position: "relative",
            top: `${top}px`,
            height: `calc(100% - ${top}px)`,
        });
    };

    // Recalculate layout for all or specific tabs
    this.recalculate = (force = false, onlyTabIndex = null) => {
        if (!FootballManiaConfig?.graphics?.animations || !state.tabview || !state.collapser) return;

        if (!force && state.lastRecalculateWasCollapsed !== null &&
            state.isCollapsed === state.lastRecalculateWasCollapsed) {
            return;
        }

        const collapserHeight = this.getCollapserHeight();
        state.lastRecalculateWasCollapsed = state.isCollapsed;

        if (state.isCollapsed) {
            const transform = `translate(0px,${-collapserHeight}px)`;
            jQuery(`#${state.collapser.getClientId()}`).css({ transform, "transition-duration": "0ms" });
            jQuery(`#${state.tabview.tabHolder.getClientId()}`).css({ transform, "transition-duration": "0ms" });
        }

        const indices = onlyTabIndex !== null ? [onlyTabIndex] : state.tabs.map((_, i) => i);
        indices.forEach((i) => this.recalculateTab(i));
    };

    // Auto-collapse or open based on scroll position
    this.autoCollapseOrOpen = (scrollPosition) => {
        if (!state.collapser || !state.tabview) return;

        const collapserHeight = this.getCollapserHeight();
        const delay = 300;

        state.isCollapsed = state.currentTranslateY === -collapserHeight;
        state.isVirtuallyCollapsed = state.isCollapsed;

        if (state.currentTranslateY === 0 || state.currentTranslateY === -collapserHeight) {
            this.recalculate();
            return;
        }

        const threshold = (collapserHeight * (1 / state.reducerRatio)) / 2;
        const shouldCollapse = scrollPosition > threshold;
        const targetY = shouldCollapse ? -collapserHeight : 0;

        const transform = `translate(0px,${targetY}px)`;
        jQuery(`#${state.collapser.getClientId()}`).css({ transform, "transition-duration": `${delay}ms` });
        jQuery(`#${state.tabview.tabHolder.getClientId()}`).css({ transform, "transition-duration": `${delay}ms` });

        state.currentTranslateY = targetY;
        state.isCollapsed = shouldCollapse;
        state.isVirtuallyCollapsed = shouldCollapse;
        this.onCollapseChanged?.();

        clearTimeout(state.tempTimeout);
        state.tempTimeout = setTimeout(() => this.recalculate(), delay);
    };

    // Prepare initial state
    this.prepare = () => {
        if (!FootballManiaConfig?.graphics?.animations || !state.collapser || !state.tabview) return;

        const collapserHeight = this.getCollapserHeight();
        if (state.isCollapsed) {
            state.isVirtuallyCollapsed = true;
            const transform = `translate(0px,${-collapserHeight}px)`;
            jQuery(`#${state.collapser.getClientId()}`).css({ transform, "transition-duration": "0ms" });
            jQuery(`#${state.tabview.tabHolder.getClientId()}`).css({ transform, "transition-duration": "0ms" });
        }
    };

    // Setup scroll and touch listeners
    this.run = () => {
        if (!FootballManiaConfig?.graphics?.animations || !state.tabview || !state.tabs.length) return;

        state.lastListIndexScrolling = state.tabview.currentActiveTab;
        this.recalculate();

        state.touchStartMethod = () => {
            state.isTouching = true;
            const tabId = state.tabs[state.tabview.currentActiveTab]?.getClientId();
            const scrollElement = document.querySelector(`#${tabId}`);
            if (scrollElement) {
                state.lastScrollPositionAfterTouchStart = scrollElement.scrollTop;
                state.lastYOffsetAfterTouchStart = state.currentTranslateY;
            }
        };

        state.touchEndMethod = () => {
            state.isTouching = false;
        };

        jQuery("body").on({
            touchstart: state.touchStartMethod,
            touchend: state.touchEndMethod,
        });

        state.tabs.forEach((tab, index) => {
            const $scrollEl = jQuery(`#${tab.getClientId()}`);
            let prevScroll = null;
            let ticking = false;

            const scrollHandler = (e) => {
                if (ticking || state.ignoreEvents || index !== state.tabview.currentActiveTab) return;
                if (state.ignoreEventForIndex.has(index)) {
                    state.ignoreEventForIndex.delete(index);
                    return;
                }

                window.requestAnimationFrame(() => {
                    ticking = true;
                    const topOffset = e.originalEvent.target.scrollTop;
                    if (prevScroll === topOffset) {
                        ticking = false;
                        return;
                    }

                    state.lastListIndexScrolling = index;
                    clearTimeout(state.tempTimeout);

                    if (!state.isTouching) {
                        state.tempTimeout = setTimeout(() => {
                            state.lastScrollPositionAfterTouchStart = -1;
                            this.autoCollapseOrOpen(topOffset);
                        }, 250);
                    }

                    const collapserHeight = this.getCollapserHeight();
                    let scrollDiff;

                    if (!state.alwaysCollapseOnBackScrolling || state.lastScrollPositionAfterTouchStart === -1) {
                        scrollDiff = Math.max(-topOffset, -collapserHeight);
                    } else if (state.isCollapsed && state.isVirtuallyCollapsed) {
                        const t = ((state.lastScrollPositionAfterTouchStart - topOffset) * state.reducerRatio) - collapserHeight;
                        scrollDiff = Math.max(-collapserHeight, Math.min(0, t));
                    } else {
                        const t = ((state.lastScrollPositionAfterTouchStart - topOffset) * state.reducerRatio) + state.lastYOffsetAfterTouchStart;
                        scrollDiff = Math.min(0, Math.max(-collapserHeight, t));
                    }

                    state.currentTranslateY = scrollDiff;
                    state.isVirtuallyCollapsed = scrollDiff === -collapserHeight;

                    const transform = `translate(0px,${scrollDiff}px)`;
                    state.collapser.setCss({ transform, "transition-duration": `${state.transitionDuration}ms` });
                    state.tabview.tabHolder.setCss({ transform, "transition-duration": `${state.transitionDuration}ms` });

                    prevScroll = topOffset;
                    ticking = false;
                });
            };

            state.scrollerMethods.set(tab.getClientId(), scrollHandler);
            $scrollEl.off("scroll").on("scroll", scrollHandler);
        });
    };

    // Handle window resize
    this.onWindowResize = () => this.recalculate(true);
};