export const ManiaCache = {
    maxSize: 100,
    storeName: "cachedItems",
    dbName: "footballmaniadb",
    version: 2,
    shortCacheStateKey: "shortCache",
    memoryCacheObj: new Map(), // Using Map for better performance

    // Initialize IndexedDB promisified
    async openDB() {
        if (!window.indexedDB) throw new Error("IndexedDB not supported");

        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(this.dbName, this.version);

            request.onerror = () => reject(new Error("Failed to open IndexedDB"));
            request.onblocked = () => reject(new Error("Database operation blocked"));

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName);
                }
            };

            request.onsuccess = (event) => {
                const db = event.target.result;
                db.onversionchange = () => {
                    db.close();
                    console.warn("Database version changed, connection closed");
                };
                resolve(db);
            };
        });
    },

    // Prune old entries (async/await)
    async prune() {
        try {
            const db = await this.openDB();
            const tx = db.transaction(this.storeName, "readwrite");
            const store = tx.objectStore(this.storeName);
            const weekInMs = 1000 * 60 * 60 * 24 * 7;
            let deletions = 0;

            return new Promise((resolve) => {
                const request = store.openCursor();

                request.onsuccess = (event) => {
                    const cursor = event.target.result;
                    if (!cursor) {
                        db.close();
                        resolve(deletions);
                        return;
                    }

                    const { timestamp } = cursor.value || {};
                    if (timestamp && (Date.now() - timestamp) > weekInMs) {
                        cursor.delete();
                        deletions++;
                    }
                    cursor.continue();
                };

                request.onerror = () => {
                    db.close();
                    resolve(deletions);
                };
            });
        } catch (error) {
            console.error("Error pruning cache:", error);
            return 0;
        }
    },

    // Set value with memory and IndexedDB
    async set(key, value) {
        this.setMemoryCache(key, value);

        try {
            const db = await this.openDB();
            const tx = db.transaction(this.storeName, "readwrite");
            const store = tx.objectStore(this.storeName);

            await new Promise((resolve, reject) => {
                const request = store.put({ timestamp: Date.now(), value }, key);
                request.onsuccess = resolve;
                request.onerror = reject;
            });

            tx.oncomplete = () => db.close();
        } catch (error) {
            console.error("Error setting cache:", error);
        }
    },

    // Get value with expiration check
    async get(key, expire, cb) {
        const memoryValue = this.getMemoryCache(key);
        if (memoryValue !== undefined) {
            const expireTime = this.memoryCacheObj.get(`${key}_expire`);
            if (expireTime && (Date.now() - expireTime) <= expire) {
                return cb(memoryValue);
            }
            this.deleteMemoryCache(key);
        }

        try {
            const db = await this.openDB();
            const tx = db.transaction(this.storeName, "readonly");
            const store = tx.objectStore(this.storeName);

            const result = await new Promise((resolve, reject) => {
                const request = store.get(key);
                request.onsuccess = () => resolve(request.result);
                request.onerror = reject;
            });

            tx.oncomplete = () => db.close();

            if (!result || !result.value) return cb(null);
            if ((Date.now() - result.timestamp) > expire) return cb(null);

            this.setMemoryCache(key, result.value); // Update memory cache
            return cb(result.value);
        } catch (error) {
            console.error("Error getting cache:", error);
            return cb(null);
        }
    },

    // LocalStorage short cache methods
    setShortCache(key, data) {
        try {
            localStorage.setItem(`${this.shortCacheStateKey}${key}`, JSON.stringify(data));
        } catch (error) {
            console.warn("Failed to set short cache:", error);
        }
    },

    getShortCache(key) {
        try {
            const data = localStorage.getItem(`${this.shortCacheStateKey}${key}`);
            return data ? JSON.parse(data) : null;
        } catch (error) {
            console.warn("Failed to get short cache:", error);
            return null;
        }
    },

    flushShortCache(key) {
        localStorage.removeItem(`${this.shortCacheStateKey}${key}`);
    },

    // Memory cache methods using Map
    setMemoryCache(key, value) {
        if (this.memoryCacheObj.size >= this.maxSize) {
            const firstKey = this.memoryCacheObj.keys().next().value;
            this.deleteMemoryCache(firstKey);
        }
        this.memoryCacheObj.set(key, value);
        this.memoryCacheObj.set(`${key}_expire`, Date.now());
    },

    getMemoryCache(key) {
        return this.memoryCacheObj.get(key);
    },

    deleteMemoryCache(key) {
        this.memoryCacheObj.delete(key);
        this.memoryCacheObj.delete(`${key}_expire`);
    }
};