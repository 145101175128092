import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {Label} from "../bananaframework/src/controls/Label.js";
import {Control} from "../bananaframework/src/controls/Control.js";
import {DataGrid} from "../bananaframework/src/controls/listcontrols/datagrids/Datagrid.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {ManiaPreferences} from "../util/preferences.js";
import {Localizer} from "../util/localizer.js";
import {ManiaCache} from "../util/cache.js";
import {Favorites} from "../util/favorites.js";
import {Subscriber} from "../util/subscriber.js";
import {Processors} from "../util/processors.js";
import {DateTimecode} from "../util/datetime.js";
import {CountryUtil} from "../util/country.js";
import {ManiaAds} from "../util/ads.js";

import {AnimatorLeague} from "./animators/animatorleague.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView,TabViewTab} from "../components/tabview.js";
import {ErrorMessage,NoDataMessage} from "../components/errormessage.js";
import {LazyTileGridRenderer} from "../components/lazytilegridrenderer.js";
import {SimpleTileGridRenderer} from "../components/simpletilegridrenderer.js";
import {LeagueSelectModal} from "../components/league/leagueselectmodal.js";
import {FavoriteLeagueAdderModal} from "../components/home/favoriteleagueaddermodal.js";
import {FavoriteTeamAdderModal} from "../components/home/favoriteteamaddermodal.js";

import {TopscorersListRender} from "../components/team/listrendersteam.js";

import {MatchItemRender, MatchHeaderDateRender, StandingsListRender, MrecItemRender
} from "../components/home/listrendershome.js";
import {SeasonSelectmodal} from "../components/league/seasonselectmodal.js";
import {LeagueTrophiesListRender} from "../components/league/listrendersleague.js";
import {Remapper}   from "./../util/remapper.js";

export class League extends BasePage{

    init () {
        this.pageName = "League";
        this.version = 1;
        super.init();
        this.setStyle("height:100%;");

        //this is a bit hacky.
        //the id change event is fired even when we set the id of this page before
        //this logic. The reason is because the onhashchange event is async
        //and altough we change the url before this logic, the onhashchange event fires
        //afte this logic
        this.bind("renderComplete",()=>{

            //alert("listen")
            //we do this to register back button scenario where page A is match and page B is also match
            setTimeout(function() {
                UrlManager.listenModule('lid', function () {
                    var id = UrlManager.getModule('lid');
                    if (id == undefined || UrlManager.getModule('section') != "League") {
                        return console.warn("ignore opening league , probably navigating to other screen");
                    }
                    AppInstance.loadPage("League", {"lid": id}, false);
                });
            },100);

        });

        this.pageName = "League_"+UrlManager.getModule("lid");

        this.addCssClass("LeaguePage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorLeague();

        Router.disallowBackButtonQuitApp();

        this.createPageLayout();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }
    }

    onWorkerMessageReceived (e){
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        //this.tabview.tabs[0].hideLoader();
        this.fixturesListrender.setDataSource(e.data);
        this.autoInterval();
    }

    storeState (){

        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "matchstatsScrollPosition" : this.fixturesListrender.getScroll(),
            "headerCollapsed" : this.coordinatoranimator.isCollapsed
        }

        if (this.standingsListrender){
            d.standingListrenderScrollPosition = this.standingsListrender.getScroll();
        }

        if (this.topscorersListrender){
            d.topscorersListrenderScrollPosition = this.topscorersListrender.getScroll();
        }

        if (this.trophiesListrender){
            d.trophiesListrenderScrollPosition = this.trophiesListrender.getScroll();
        }

        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.fixturesLoadHandler);
        this.modal = null;
        UrlManager.unlistenModule('lid');
        UrlManager.unlistenModule('tableSeason');


        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
    }

    onOffline() {
        this.getPageTemplate().createMenuLeague();
    }
    onOnline() {
        this.autoInterval(0);
        Router.showBanner();
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){
            this.autoInterval(0);
            Router.showBanner();
        }
        this.previousDocumentHidden = document.hidden;
        //console.log("visibility change ",document.hidden,this);
    }

    createPageLayout (){
        var row2 = new Panel();
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents (){
        this.getPageTemplate().showTitle();
    }

    onWindowResize (){
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.fixturesListrender) {
            this.fixturesListrender.onWindowResize();
        }

        if (this.standingsListrender){
            this.standingsListrender.onWindowResize();
        }

        if (this.topscorersListrender){
            this.topscorersListrender.onWindowResize();
        }

        if (this.trophiesListrender){
            this.trophiesListrender.onWindowResize();
        }
    }

    updateDisplay (){
        this.runApp();
    }

    runApp (){

        console.log("run app")

        this.showLoader();

        if (!AppInstance.setupAlreadyDone) {
            this.showLoader();
        }

        AppInstance.setup((result)=>{

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",()=>{
                    this.runApp();
                });
            }

            DataProvider.getLeagueMatches(UrlManager.getModule("lid"),(response)=>{

                this.hideLoader();

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); }

                if (!response.isOk()){

                    if (response.getStatus() == 404) {
                        return this.showPageDataDoesNotExist("", ()=> {
                            this.runApp();
                        });
                    }
                    else{
                        return this.showError("", ()=> {
                            this.runApp();
                        });
                    }
                }

                var data = response.getData();
                this.cachedData = data;

                if (data.topscorers && data.topscorers.tournaments.length && data.topscorers.tournaments[0].players.length < 3) {
                    data.topscorers = null;

                }

                this.createTitle(data);
                this.createTabs(data);

                this.getPageTemplate().createMenuLeague(response.getData());

                this.tabview.bind("tabChanged",()=>{
                    this.storeState();
                    this.coordinatoranimator.tabChange();
                    console.log("-------------------> tab changed");
                    // this.loadStandings(this.cachedData);
                    // this.loadTopscorers(this.cachedData);
                    // this.loadFixtures(this.cachedData);
                    // this.loadTrophies(this.cachedData);
                });

                var storeHandler = null;
                var storeHandler2 = null;
                var storeHandler3 = null;
                this.fixturesListrender.bind("scroll",(e,scroll)=> {
                    clearTimeout(storeHandler);
                    storeHandler = setTimeout(()=> {
                        this.storeState();
                    },200)
                });

                var standings = false;
                var topscorers = false;

                if (this.standingsListrender) {
                    standings = true;
                    this.standingsListrender.bind("scroll", (e, scroll)=> {
                        clearTimeout(storeHandler2);
                        storeHandler2 = setTimeout(()=> {
                            this.storeState();
                        }, 200);
                    });

                    this.standingsListrender.bind("onRequestSeasonSelector", ()=>{
                       this.openSeasonSelectModal();
                    });

                    this.bind("seasonSelect", (e, season)=>{
                       console.log("seasonSelect    ", season);

                       var startYear = season.start_date[0]+""+season.start_date[1]+""+season.start_date[2]+""+season.start_date[3];
                       var endYear = season.end_date[0]+""+season.end_date[1]+""+season.end_date[2]+""+season.end_date[3];

                       console.log("current",this.cachedData.table.season, "choice",startYear+"/"+endYear)

                       if (this.cachedData.table.season == startYear+"/"+endYear){
                           console.log("remove module");
                           UrlManager.removeModule("tableSeason");
                           UrlManager.updateUrl(true);
                       }
                       else {
                           console.log("add module");
                           UrlManager.registerModule("tableSeason");
                           UrlManager.setModule("tableSeason", startYear + "-" + endYear);
                       }

                       this.loadStandings(this.cachedData);
                    });

                    //called when pressing back button.
                    UrlManager.listenModule("tableSeason", (module, value)=>{
                        this.loadStandings(this.cachedData);
                    });
                }

                if (this.topscorersListrender) {
                    topscorers = true;
                    this.topscorersListrender.bind("scroll", (e, scroll)=> {
                        clearTimeout(storeHandler3);
                        storeHandler3 = setTimeout(()=> {
                            this.storeState();
                        }, 200);
                    });
                }

                if (this.trophiesListrender) {
                    this.trophiesListrender.bind("scroll", (e, scroll)=> {
                        clearTimeout(storeHandler3);
                        storeHandler3 = setTimeout(()=> {
                            this.storeState();
                        }, 200);
                    });
                }

                if (FootballManiaConfig.isRtl){
                    this.tabview.currentActiveTab = this.tabview.tabs.length - 1;
                }

                var extraData = UrlManager.getModule("extradata");
                if (extraData){

                    try {
                        extraData = JSON.parse((decodeURI(atob(extraData))));

                        if (extraData.currentActiveTab != null){
                            this.tabview.currentActiveTab = extraData.currentActiveTab;
                        }
                        else if (extraData.action == "showStandings") {
                            this.tabview.currentActiveTab = 1;
                        }

                        UrlManager.removeModule("extradata")
                    }
                    catch (e){
                        console.error(e);
                    }
                }

                var previousState = StateManager.getCurrentPageState(this.pageName);
                if (previousState) {
                    this.tabview.currentActiveTab = previousState.tabIndex;
                    this.fixturesListrender.scrollTo(previousState.matchstatsScrollPosition || 0);
                    console.log("restored scroll ",previousState.matchstatsScrollPosition)

                    if (this.standingsListrender) {
                        this.standingsListrender.scrollTo(previousState.standingListrenderScrollPosition || 0);
                    }
                    if (this.topscorersListrender) {
                        this.topscorersListrender.scrollTo(previousState.topscorersListrenderScrollPosition || 0);
                    }
                    this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
                }

                this.contentContainer.invalidateDisplay();
                this.coordinatoranimator.prepare();

                this.loadFixtures(data);

                if (this.standingsListrender && data.table && data.table.groups.length) {
                    this.loadStandings(data);
                }
                if (this.topscorersListrender && data.topscorers && data.topscorers.tournaments.length && data.topscorers.tournaments[0].players.length) {
                    this.loadTopscorers(data);
                }
                if (this.trophiesListrender && data.trophies) {
                    this.loadTrophies(data);
                }
            },false);
        });
    }

    openLeagueSelectModal (leagueOpenTabIndex) {
        var urlIdentifier = "leagueSelectModal";
        var pan = new LeagueSelectModal(urlIdentifier, true, this.cachedData.info);
        pan.currentActiveTab = this.tabview.currentActiveTab
        pan.enableUrlHistory = false;
        this.modal = pan;
        this.getPage().addControl(pan, true);
    }

    openSeasonSelectModal (leagueOpenTabIndex) {
        var urlIdentifier = "seasonSelectModal";
        var pan = new SeasonSelectmodal(urlIdentifier, true, this.cachedData);
        pan.currentActiveTab = this.tabview.currentActiveTab
        pan.enableUrlHistory = false;
        this.modal = pan;
        this.getPage().addControl(pan, true);
    }

    createTitle (data){
        if (!data.info) return;

        var wrapper = new Panel();
        wrapper.addCssClass("flexVerticalAlign");
        wrapper.setStyle("height: 43px;");

        var con = new Panel();
        con.setStyle("width:100%;justify-content: center;")
        con.addCssClass("flexHorizontalAlign");

        var image = new Image();
        image.setStyle("width:28px;")


        image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+Remapper.remapCountryLogo(data.info.keygs,data.info.countrygs.replaceAll(" ","-").toLowerCase())+".png");

        var title = new Panel();

        if (!AppInstance.getConfig().enabledFeatures.showCompleteLeagueNames){
            var regex = new RegExp(AppInstance.getConfig().reservedWords.join("|"), "g");
            data.info.title = data.info.title.replace(regex, "");
        }

        if (FootballManiaConfig.hideCountryTitleFor.indexOf(data.info.countrygs) != -1){
            title.addControl(data.info.title)
        }
        else{
            title.addControl(CountryUtil.getLocalizedCountryByName(data.info.countrygs));
            title.addControl(" - "+data.info.title)
        }

        if (data.info.subtype){
            title.addControl(" - ");
            title.addControl(data.info.subtype);
        }

        con.addControl(image);
        con.addControl(title);
        con.addControl("<p class='arrow-down-choice' style='margin-top:3px; margin-inline-start:6px;'></p>");
        wrapper.addControl(con);

        this.getPageTemplate().topTitleContainer.addControl(wrapper);
        //this.getPageTemplate().topTitleContainer.addControl(subtitle);
        this.getPageTemplate().topTitleContainer.invalidateDisplay();

        con.bind("click",()=>{
            this.openLeagueSelectModal(this.cachedData);
        });
    }

    createTabs (data){
        var tabview = new TabView();
        this.tabview =tabview;
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        var tab2;
        var tab3;
        var tab4;

        tab1 = new TabViewTab();
        //tab1.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
        tab1.addCssClass("listViewScrollableContainer");
        tab1.title = Localizer.getString("schedule");
        tab1.iconClass = "icon-shedule";

        var offset  = 0;
        if (data.table && data.table.groups.length) {
            tab2 = new TabViewTab();

            var con = new Panel();
            con.setStyle("width: 100%;position:absolute;");

            var top = new Panel();
            top.addCssClass("tableHeader");
            top.setStyle("background-color:red;height:50px;width:100%;");
            var bottom = new Panel();
            bottom.addCssClass("tableFooter");

            var scroller = new Panel();
            scroller.addCssClass("scroller");
            //con.addControl(top);
            con.addControl(scroller);
            con.addControl(bottom);

            this.tableFooter = bottom;
            this.tableScroller = scroller;

            //tab2.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
            tab2.title = Localizer.getString("standen");
            tab2.scrollerContainerReference = scroller;
            tab2.iconClass = "icon-listorder";
            tab2.addControl(con);

            this.createTableFooterDescriptions();
        }

        if (data.topscorers && data.topscorers.tournaments.length && data.topscorers.tournaments[0].players.length) {
            tab3 = new TabViewTab();
            //tab3.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
            tab3.addCssClass("listViewScrollableContainer");
            tab3.title = Localizer.getString("topscoreplayers");
            tab3.iconClass = "icon-shoe";
        }

        if (data.trophies) {
            tab4 = new TabViewTab();
            //tab3.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
            tab4.addCssClass("listViewScrollableContainer");
            tab4.title = Localizer.getString("trophies");
            tab4.iconClass = "icon-trophy";
        }


        var index = 0;
        if (FootballManiaConfig.isRtl){
            if (tab4){
                this.createTrophiesControls(tab4,index);
                this.coordinatoranimator.addScrollerContainer(tab4);
                tabs.push(tab4);
                index++;
            }
            if (tab3){
                this.createTopscorersControls(tab3,index);
                this.coordinatoranimator.addScrollerContainer(tab3);
                tabs.push(tab3);
                index++;
            }
            if (tab2){
                this.createStandingsControls(scroller, index);
                this.coordinatoranimator.addScrollerContainer(scroller);
                tabs.push(tab2);
                index++;
            }
            if (tab1){
                this.createFixturesControls(tab1,index);
                this.coordinatoranimator.addScrollerContainer(tab1);
                tabs.push(tab1);
                index++;
            }
        }
        else{
            if (tab1){
                this.createFixturesControls(tab1,index);
                this.coordinatoranimator.addScrollerContainer(tab1);
                index++;
                tabs.push(tab1);
            }
            if (tab2){
                this.createStandingsControls(scroller, index);
                this.coordinatoranimator.addScrollerContainer(scroller);
                index++;
                tabs.push(tab2);
            }
            if (tab3){
                this.createTopscorersControls(tab3,index);
                this.coordinatoranimator.addScrollerContainer(tab3);
                index++;
                tabs.push(tab3);
            }
            if (tab4){
                this.createTrophiesControls(tab4,index);
                this.coordinatoranimator.addScrollerContainer(tab4);
                tabs.push(tab4);
                index++;
            }
        }

        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady",()=>{
           this.coordinatoranimator.run();
        });

        tab1.showLoader();

        if (tab2) {
            tab2.showLoader();
        }
        if (tab3){
            tab3.showLoader();
        }

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
            if (tab2){
                scroller.setCss({'height': '(100vh - '+this.tableViewHeightOffset+'px)  '});
                tab2.setCss({'height': '100%'});
            }
            if (tab3) tab3.setCss({'height': '100%'});
            if (tab4) tab4.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px");
            if (tab1) tab1.setCss({'height': 'calc(100% - 50px)'});
            if (tab2){
                bottom.setCss({'bottom': '50px'});
                scroller.setCss({'height': 'calc(100% - '+this.tableViewHeightOffset+'px)'});
            }
            if (tab3) tab3.setCss({'height': 'calc(100% - 50px)'});
            if (tab4) tab4.setCss({'height': 'calc(100% - 50px)'});
        });
        this.getPageTemplate().loadStickyBannerAd();

        console.log("load mrec banner ad",this.cachedData);

        this.loadMediumRectangle();
    }

    loadMediumRectangle (noAutoInterval) {

        if (FootballManiaConfig.isPremium) return;

        //change 1/3 to continue
        // if (Math.random() > 0.33) {
        //     return;
        // }
        ManiaAds.loadMediumRectangle((error,data) => {
            if (error) {
                //this.stickyBannerTargetContainer.setVisible(false);
                console.warn("error loading mrec banner", error);
            } else {
                //this.adContent.setVisible(true);
                console.log("mrec ad loaded", this.cachedData,data);
                this.adContentMrec = data;
                if (!noAutoInterval) {
                    this.autoInterval(1);
                }
            }
        });
    }

    createTableFooterDescriptions (){

        this.tableFooter.clear();
        console.log("createTableFooterDescriptions");
        var data = this.cachedData;

        if (data.historyTable){
            var groups = data.historyTable.groups;
        }
        else{
            groups = data.table.groups;
        }

        var descriptions = {};
        for (var i=0; i < groups.length;i++){

            for (var j=0; j < groups[i].teams.length;j++) {
                if (!groups[i].teams[j].description_v2) continue;
                descriptions[groups[i].teams[j].description_v2] = data.table.groups[i].teams[j].color;
            }
        }

        var heightFooter = 0;
        for (var description in descriptions){
            var con2 = new Panel();
            con2.addCssClass("flexHorizontalAlign")
            con2.setStyle("height:15px;justify-content: flex-start;")
            var bar = new Panel();
            var text = new Panel();
            bar.setStyle("margin-inline-end:4px;height:10px;width:20px;background-color:"+descriptions[description]);
            con2.addControl(bar);
            text.addControl(description);
            con2.addControl(description);
            console.log("description",description);
            this.tableFooter.addControl(con2);
            heightFooter += 15;
        }
        heightFooter +=10;

        this.tableViewHeightOffset = heightFooter+50;
        ///set fixed height of scroller container table items.
        //this is needed because bottom description container is dynamic in height
        this.tableFooter.setCss({"height":heightFooter+"px"});
        this.tableScroller.setCss({"width":"100%","height":"calc(100vh - "+this.tableViewHeightOffset+"px)","position":"relative","overflow-y": "scroll","transform": "translate(0,0)"});
        this.tableFooter.invalidateDisplay();
    }

    createFixturesControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.enableFastScroll = true;

        listRender.setItemRender(function(data){

            if (data.type == 2) {
                return {
                    typeId: 2,
                    render: new MatchHeaderDateRender(),
                };
            }
            else if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new MatchItemRender(),
                };
            }
            else if (data.type == 3) {
                return {
                    typeId: 1,
                    render: new MrecItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(cb){
            return new NoDataMessage({
                cb : function(){
                    history.back();
                },
                message : Localizer.getString("no_schedule_available")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
        });

        listRender.fastScrollElementTemplate = ()=>{
            var panel = new Control();
            var label = new Panel();
            panel.addControl(label);
            return panel;
        }

        var dt = new DateTimecode();
        listRender.bind('requestFastScrollIndicatorItem',(e,data)=>{
            if (!data.item){
                return;
            }
            console.log(data.item);

            var label = data.element.controls[0];

            var timeInfo = "";
            if (data.item.dayTimecodeLocalized) {
                dt.setTimecode(data.item.dayTimecodeLocalized);
                timeInfo = dt.toLocaleDateString();
            }

            var roundInfo = "";
            if (data.item.week) {
                roundInfo = Localizer.getString("round")+" "+data.item.week;
            }
            else if (data.item.stageName) {
                roundInfo = data.item.stageName;
            }
            else if (data.item.round) {
                roundInfo = data.item.round;
            }

            label.clear();
            label.addControl("<b>"+timeInfo+"</b><br>"+roundInfo+"</br></b>",true);
        });

        listRender.setPlaceHolderWidth("100%;");

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.fixturesScrollPosition || 0);
        }

        container.addControl(listRender);

        this.fixturesListrender = listRender;

        tab.addControl(container);
    }

    createStandingsControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new StandingsListRender(),
                };
            }
        })

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
        })

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            console.log("restore scroll #2 : ",previousState.standingListrenderScrollPosition)
            listRender.scrollTo(previousState.standingListrenderScrollPosition || 0);
        }

        container.addControl(listRender);
        this.standingsListrender = listRender;

        tab.addControl(container);
    }

    createTopscorersControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        console.log("init topscorers list render",this.tabview)
        var listRender = new SimpleTileGridRenderer();
        listRender.tabview = this.tabview;
        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TopscorersListRender(),
                };
            }
        })

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
        });

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            console.log("restore scroll #2 : ",previousState.topscorersListrenderScrollPosition)
            listRender.scrollTo(previousState.topscorersListrenderScrollPosition || 0);
        }

        container.addControl(listRender);
        this.topscorersListrender = listRender;

        tab.addControl(container);
    }

    createTrophiesControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();
        listRender.tabview = this.tabview;
        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new LeagueTrophiesListRender(),
                };
            }
        })

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
        });

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            console.log("restore scroll trophiesListrenderScrollPosition : ",previousState.trophiesListrenderScrollPosition)
            listRender.scrollTo(previousState.trophiesListrenderScrollPosition || 0);
        }

        container.addControl(listRender);
        this.trophiesListrender = listRender;

        tab.addControl(container);
    }

    loadFixtures (data,ignoreCache){

        if (!data){
            return this.autoInterval();
        }

        if (!this.fixturesListrender){ return console.warn("cannot load fixtures with list"); };

        if (this.tabview.currentActiveTab != this.fixturesListrender.containerTarget.tabIndex){
            //return;
        }

        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        var options = {
            methodType: 3,
            responseData : data,
            fontSize : FootballManiaConfig.size,
            dontSelectByCurrentData : false,
            translations : Localizer.getLoadedLocale(),
            defaultTranslations : Localizer.DefaultTranslations,
            mrecAdData : this.adContentMrec,
            listType : ManiaPreferences.getMatchListType()
        }

        if (!FootballManiaConfig.useWebWorkers) {
            var list = Processors.prepareFixtureData(options);
            var data = {data:list};
            this.onWorkerMessageReceived(data);
        } else {
            AppInstance.workerHelper1.postMessage(options);
        }
        this.tabview.tabs[this.fixturesListrender.containerTarget.tabIndex].hideLoader();
        Router.disallowBackButtonQuitApp();
    }

    loadStandings (data,ignoreCache){
        console.log("load standings",data);
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        if (!this.standingsListrender){ return console.warn("cannot load standings with list"); };

        if (this.tabview.currentActiveTab != this.standingsListrender.containerTarget.parent.parent.tabIndex){
            //return;
        }

        var urlTableSeason = UrlManager.getModule("tableSeason");

        console.log("url table season",urlTableSeason,this.cachedData);
        var proceed = true;
        //if end is year now
        if (urlTableSeason){

            var lastYearSelector = urlTableSeason.slice(5, 9);    
            var lastYearAvailableInSelections = this.cachedData.tableHistory.seasons[this.cachedData.tableHistory.seasons.length-1].end_date.split("-")[0];


            if (lastYearSelector == lastYearAvailableInSelections){
                proceed = false;
                this.cachedData.historyTable = null;
            }
            

            // var endYear = urlTableSeason.slice(5, 9);
            // var currentYear = new Date().getFullYear();
            // if (endYear == currentYear){
            //     proceed = false;
            //     this.cachedData.historyTable = null;
            // }
        }
        else{
            this.cachedData.historyTable = null;
            proceed = false;
        }

        console.log("no proceed");

        if (proceed){
            //  this.standingsListrender.showLoader();
            this.tabview.tabs[this.standingsListrender.containerTarget.parent.parent.tabIndex].showLoader();
            var key = UrlManager.getModule("lid");
            var season = UrlManager.getModule("tableSeason");
            key = key+"_"+season;
            DataProvider.getHistoryTable(key,(data)=>{
                if (!data.isOk()){
                    console.error("loading history table error. fall back to current table");
                    UrlManager.removeModule("tableSeason");
                    UrlManager.updateUrl(true);
                    return this.loadStandings(this.cachedData)
                }
                this.cachedData.historyTable = data.getData();
                this.cachedData.historyTable.groups = [];

                //if is string
                if (typeof this.cachedData.historyTable.season != "string") {
                    //var startYear this.cachedData.table.season
                    const yearStart = this.cachedData.historyTable.season.start_date.slice(0, 4);
                    const yearEnd = this.cachedData.historyTable.season.end_date.slice(0, 4);

                    this.cachedData.historyTable.season = yearStart + "/" + yearEnd;
                }
                for (var i=0; i < this.cachedData.historyTable.rounds.length;i++){
                    var round = this.cachedData.historyTable.rounds[i];

                    if (round.groups){
                        for (var j=0; j < round.groups.length;j++){
                            var group = round.groups[j];
                            group.round = "";
                            group.name = group.group;
                            //group.group = "";
                            group.teams = group.tables.total.teams;
                            this.cachedData.historyTable.groups.push(group);
                        }
                    }
                    else {
                        var group = {
                            country: round.country,
                            group: "",
                            round: round.round,
                            name: round.name,
                            teams: round.tables.total.teams
                        };

                        this.cachedData.historyTable.groups.push(group);
                    }

                    var listData = [];
                    var item = {"data": this.cachedData, "type": 1};
                    listData.push(item);

                    console.log("hide loader standings index", this.standingsListrender.containerTarget.parent.parent.tabIndex);
                }
                this.tabview.tabs[this.standingsListrender.containerTarget.parent.parent.tabIndex].hideLoader();
                this.standingsListrender.setDataSource(listData);
                this.createTableFooterDescriptions();
                //clonedData.data = data.getData();
            });
        }
        else {
            var timeout = 0;
            if (this.tabview.currentActiveTab != this.standingsListrender.containerTarget.parent.parent.tabIndex) {
                timeout = 1000;
            }

            console.log("load standings ", data);

            setTimeout(() => {
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                ;

                var listData = [];
                var item = {"data": data, "type": 1};
                listData.push(item);

                console.log("hide loader standings index", this.standingsListrender.containerTarget.parent.parent.tabIndex);
                this.tabview.tabs[this.standingsListrender.containerTarget.parent.parent.tabIndex].hideLoader();
                this.standingsListrender.setDataSource(listData);
                this.createTableFooterDescriptions();
            }, timeout);
        }
    }

    loadTopscorers (data,ignoreCache){

        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        if (!this.topscorersListrender){ return console.warn("cannot load topscorers with list"); };

        if (this.tabview.currentActiveTab != this.topscorersListrender.containerTarget.tabIndex){
            //return;
        }

        var timeout = 0;
        if (this.tabview.currentActiveTab != this.topscorersListrender.containerTarget.tabIndex){
            timeout = 1500;
        }
        setTimeout(()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            var listData = [];
            var topscorer;
            var tournament;

            for (var i=0; i < data.topscorers.tournaments.length;i++){
                tournament = data.topscorers.tournaments[i];
                for (var j=0; j < tournament.players.length; j++) {

                    topscorer = tournament.players[j];
                    topscorer.type = 1;
                    topscorer.style = {"height": 50};
                    listData.push(topscorer);
                }
            }
            console.log("hide loader standings index",this.topscorersListrender.containerTarget.parent.parent.tabIndex);
            this.tabview.tabs[this.topscorersListrender.containerTarget.tabIndex].hideLoader();
            this.topscorersListrender.setDataSource(listData);
        },timeout);
    }

    loadTrophies (data,ignoreCache){
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        if (!this.trophiesListrender){ return console.warn("cannot load trophies with list"); };

        // if (this.tabview.currentActiveTab != this.trophiesListrender.containerTarget.tabIndex){
        //     return;
        // }

        var timeout = 0;
        if (this.tabview.currentActiveTab != this.trophiesListrender.containerTarget.tabIndex){
            timeout = 900;
        }
        setTimeout(()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            var listData = [];
            var trophy;

            console.log("load trophies ",data);

            for (var i=0; i < data.trophies.seasons.length;i++){
                trophy = data.trophies.seasons[i];
                trophy.type = 1;
                trophy.style = {"height": 50};
                listData.push(trophy);
            }
            console.log("list data trophies",listData);
            console.log("hide loader trophy index",this.trophiesListrender.containerTarget.parent.parent.tabIndex);
            this.tabview.tabs[this.trophiesListrender.containerTarget.tabIndex].hideLoader();

            var listData = [];
            var item = {"data": this.cachedData, "type": 1};
            listData.push(item);
            this.trophiesListrender.setDataSource(listData);
        },timeout);
    }

    autoInterval (overrideInterval){

        clearTimeout(this.fixturesLoadHandler);
        this.fixturesLoadHandler = setTimeout(()=>{

            this.loadMediumRectangle(true);

            if (document.hidden){
                console.warn("no auto refresh because page is hidden");
                return;
            }

            DataProvider.getLeagueMatches(UrlManager.getModule("lid"),(response)=> {

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

                if (!response.isOk()){
                    this.autoInterval();
                    return;
                }


                //todo error screen
                this.cachedData = response.getData();
                this.cachedData.adContentMrec = this.adContentMrec;

                this.getPageTemplate().createMenuLeague(response.getData());

                this.loadFixtures(response.getData());

                if (this.standingsListrender) {
                    this.loadStandings(response.getData());
                }

                if (this.topscorersListrender) {
                    this.loadTopscorers(response.getData());
                }

            });
        },overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.league)
    }
};