import {CountryUtil} from "../util/country.js";
import {Localizer} from "../util/localizer.js";
import {MatchUtil} from "../util/matchutil.js";

export const Processors = {

    prepareTodayFeedData : function(options) {
        // Destructure options once at the top
        const {
            translations,
            defaultTranslations,
            responseData: countries,
            fixedRegions,
            fixedLeagueOrder,
            favoriteTeams,
            favoriteLeagues,
            hiddenTitles,
            locale,
            enabledFeatures,
            userRegionLocale,
            showOnlyFavorites,
            subscribedTeams,
            subscribedLeagues,
            subscribedMatches,
            fontSize,
            userCountry,
            listType
        } = options;

        // Set up localization
        Localizer.LocalizedTranslations = translations;
        Localizer.DefaultTranslations = defaultTranslations;

        // Constants
        const MATCH_HEIGHT_LARGE = 60;
        const MATCH_HEIGHT_TYPE3 = 70;
        const MATCH_HEIGHT_DEFAULT = 55;
        const HEADER_HEIGHT = 45;
        const MATCH_TYPE = 1;
        const HEADER_TYPE = 2;

        // Determine item height in one expression
        const itemHeight = listType === "3" ? MATCH_HEIGHT_TYPE3 :
            (fontSize === "xlarge" || fontSize === "large") ? MATCH_HEIGHT_LARGE :
                MATCH_HEIGHT_DEFAULT;

        // Pre-process fixed regions
        const foundFixedRegions = fixedLeagueOrder[userRegionLocale] || {};
        const foundFixedLeagues = foundFixedRegions.leagues || [];
        const foundFixedCountries = foundFixedRegions.countries || [];

        // Use Map for O(1) lookups instead of arrays with indexOf
        const favoriteTeamsMap = new Map(Object.entries(favoriteTeams));
        const favoriteLeaguesMap = new Map(Object.entries(favoriteLeagues));
        const subscribedTeamsSet = new Set(subscribedTeams);
        const subscribedLeaguesSet = new Set(subscribedLeagues);
        const subscribedMatchesSet = new Set(subscribedMatches);
        const hiddenTitlesSet = new Set(hiddenTitles);

        // Process data in a single pass
        const listData = [];
        let headerId = 0;

        for (const country of countries) {
            const localizedCountry = CountryUtil.getLocalizedCountryByName(country.country, locale);
            const fixedCountryIndex = foundFixedCountries.indexOf(country.country);
            let foundFixedIndex = 1;

            for (const league of country.leagues) {
                headerId++;
                const leagueKey = league.key;
                const isLeagueSubscribed = subscribedLeaguesSet.has(leagueKey);
                const fixedLeagueIndex = foundFixedLeagues.indexOf(leagueKey);
                const isFixedToTop = fixedLeagueIndex !== -1 || fixedCountryIndex !== -1;
                const fixedIndex = fixedLeagueIndex !== -1 ? fixedLeagueIndex :
                    fixedCountryIndex !== -1 ? foundFixedIndex + 200 : null;

                if (isFixedToTop) foundFixedIndex++;

                for (const match of league.matches) {
                    const localTeamId = match.gs_localteamid;
                    const visitorTeamId = match.gs_visitorteamid;
                    const matchId = String(match.id);

                    // Build match object
                    const matchData = {
                        ...match,
                        title: `${match.localteam} - ${match.visitorteam}`,
                        type: MATCH_TYPE,
                        country: country.country,
                        localizedCountry,
                        league: league.league,
                        headerId,
                        style: { height: itemHeight },
                        leagueKey
                    };

                    // Fixed index handling
                    if (isFixedToTop) {
                        matchData.foundFixedIndex = fixedIndex;
                        matchData.fixedIndex = true;
                    }

                    // Favorite and subscription flags
                    const favLocal = favoriteTeamsMap.get(localTeamId);
                    const favVisitor = favoriteTeamsMap.get(visitorTeamId);
                    const favLeague = favoriteLeaguesMap.get(leagueKey);

                    matchData.isFavouritedByTeam = !!(favLocal || favVisitor);
                    matchData.favoriteTeamIndex = favLocal?.index || favVisitor?.index || null;
                    matchData.isFavouritedByLeague = !!favLeague;
                    matchData.favoriteLeagueIndex = favLeague?.index || null;
                    matchData.isMatchSubscribed = subscribedMatchesSet.has(matchId);
                    matchData.isLocalTeamSubscribed = subscribedTeamsSet.has(localTeamId);
                    matchData.isVisitorTeamSubscribed = subscribedTeamsSet.has(visitorTeamId);
                    matchData.isLeagueSubscribed = isLeagueSubscribed;

                    // Add match if it meets criteria
                    const shouldAdd = !showOnlyFavorites || matchData.isFavouritedByTeam ||
                        matchData.isFavouritedByLeague || matchData.isMatchSubscribed ||
                        matchData.isLocalTeamSubscribed || matchData.isVisitorTeamSubscribed ||
                        matchData.isLeagueSubscribed;

                    if (shouldAdd) listData.push(matchData);
                }
            }
        }

        // Sort with optimized comparison
        listData.sort((a, b) => {
            // Favorite teams
            if (a.isFavouritedByTeam !== b.isFavouritedByTeam)
                return a.isFavouritedByTeam ? -1 : 1;
            if (a.isFavouritedByTeam && b.isFavouritedByTeam)
                return a.favoriteTeamIndex - b.favoriteTeamIndex;

            // Favorite leagues
            if (a.isFavouritedByLeague !== b.isFavouritedByLeague)
                return a.isFavouritedByLeague ? -1 : 1;
            if (a.isFavouritedByLeague && b.isFavouritedByLeague)
                return a.favoriteLeagueIndex - b.favoriteLeagueIndex;

            // Fixed index
            if (a.fixedIndex !== b.fixedIndex)
                return a.fixedIndex ? -1 : 1;
            if (a.fixedIndex && b.fixedIndex)
                return a.foundFixedIndex - b.foundFixedIndex;

            // User country priority
            if (userCountry && a.country !== b.country)
                return a.country === userCountry ? -1 : 1;

            return a.localizedCountry !== b.localizedCountry ?
                String(a.localizedCountry || '').localeCompare(String(b.localizedCountry || '')) :
                a.league !== b.league ?
                    String(a.league || '').localeCompare(String(b.league || '')) :
                    a.time !== b.time ?
                        String(a.time || '').localeCompare(String(b.time || '')) :
                        String(a.localteam || '').localeCompare(String(b.localteam || ''));
        });

        // Add headers in a single pass using reduce
        const finalList = listData.reduce((acc, match, index) => {
            if (index === 0 || match.headerId !== listData[index - 1].headerId) {
                const header = {
                    country: match.country,
                    localizedCountry: match.localizedCountry,
                    league: match.league,
                    title: hiddenTitlesSet.has(match.country) ?
                        match.league :
                        `${match.localizedCountry} - ${match.league}`,
                    type: HEADER_TYPE,
                    style: { height: HEADER_HEIGHT },
                    isFavorite: match.isFavouritedByTeam || match.isFavouritedByLeague,
                    isFavoriteTeam: match.isFavouritedByTeam,
                    isFavoriteLeague: match.isFavouritedByLeague,
                    key: match.leagueKey
                };
                acc.push(header);
            }
            acc.push(match);
            return acc;
        }, []);

        return {
            listData: finalList,
            foundUnfiltered: listData.length > 0
        };
    },

        prepareLiveFeedData : function(options) {
            // Destructure options
            const {
                translations,
                defaultTranslations,
                responseData: countries,
                fixedRegions,
                fixedLeagueOrder,
                favoriteTeams,
                favoriteLeagues,
                hiddenTitles,
                locale,
                enabledFeatures,
                userRegionLocale,
                showOnlyFavorites,
                subscribedTeams,
                subscribedLeagues,
                subscribedMatches,
                fontSize,
                userCountry,
                listType
            } = options;

            // Set up localization
            Localizer.LocalizedTranslations = translations;
            Localizer.DefaultTranslations = defaultTranslations;

            // Constants
            const MATCH_HEIGHT_LARGE = 60;
            const MATCH_HEIGHT_TYPE3 = 70;
            const MATCH_HEIGHT_DEFAULT = 55;
            const HEADER_HEIGHT = 45;
            const MATCH_TYPE = 1;
            const HEADER_TYPE = 2;

            // Determine item height
            const itemHeight = listType === "3" ? MATCH_HEIGHT_TYPE3 :
                (fontSize === "xlarge" || fontSize === "large") ? MATCH_HEIGHT_LARGE :
                    MATCH_HEIGHT_DEFAULT;

            // Pre-process favorite indices
            const favoriteLeaguesMap = new Map(
                Object.entries(favoriteLeagues).map(([key], index) => [key, { index: index + 1 }])
            );
            const favoriteTeamsMap = new Map(Object.entries(favoriteTeams));

            // Use Sets for O(1) lookups
            const subscribedTeamsSet = new Set(subscribedTeams);
            const subscribedLeaguesSet = new Set(subscribedLeagues);
            const subscribedMatchesSet = new Set(subscribedMatches);
            const hiddenTitlesSet = new Set(hiddenTitles);

            // Pre-process fixed regions
            const foundFixedRegions = fixedLeagueOrder[userRegionLocale] || {};
            const foundFixedLeagues = foundFixedRegions.leagues || [];
            const foundFixedCountries = foundFixedRegions.countries || [];

            // Process data
            const listData = [];
            let headerId = 0;

            for (const country of countries) {
                const localizedCountry = CountryUtil.getLocalizedCountryByName(country.country, locale);
                const fixedCountryIndex = foundFixedCountries.indexOf(country.country);
                let foundFixedIndex = 1;

                for (const league of country.leagues) {
                    headerId++;
                    const leagueKey = league.key;
                    const isLeagueSubscribed = subscribedLeaguesSet.has(leagueKey);
                    const fixedLeagueIndex = foundFixedLeagues.indexOf(leagueKey);
                    const isFixedToTop = fixedLeagueIndex !== -1 || fixedCountryIndex !== -1;
                    const fixedIndex = fixedLeagueIndex !== -1 ? fixedLeagueIndex :
                        fixedCountryIndex !== -1 ? foundFixedIndex + 200 : null;

                    if (isFixedToTop) foundFixedIndex++;

                    for (const match of league.matches) {
                        const localTeamId = match.gs_localteamid;
                        const visitorTeamId = match.gs_visitorteamid;
                        const matchId = String(match.id);

                        // Build match object
                        const matchData = {
                            ...match,
                            title: `${match.localteam} - ${match.visitorteam}`,
                            type: MATCH_TYPE,
                            country: country.country,
                            localizedCountry,
                            league: league.league,
                            headerId,
                            style: { height: itemHeight },
                            accessibility: { focusable: true, allowTabFocus: true },
                            leagueKey
                        };

                        // Fixed index handling
                        if (isFixedToTop) {
                            matchData.foundFixedIndex = fixedIndex;
                            matchData.fixedIndex = true;
                        }

                        // Favorite and subscription flags
                        const favLocal = favoriteTeamsMap.get(localTeamId);
                        const favVisitor = favoriteTeamsMap.get(visitorTeamId);
                        const favLeague = favoriteLeaguesMap.get(leagueKey);

                        matchData.isFavouritedByTeam = !!(favLocal || favVisitor);
                        matchData.favoriteTeamIndex = favLocal?.index || favVisitor?.index || null;
                        matchData.isFavouritedByLeague = !!favLeague;
                        matchData.favoriteLeagueIndex = favLeague?.index || null;
                        matchData.isMatchSubscribed = subscribedMatchesSet.has(matchId);
                        matchData.isLocalTeamSubscribed = subscribedTeamsSet.has(localTeamId);
                        matchData.isVisitorTeamSubscribed = subscribedTeamsSet.has(visitorTeamId);
                        matchData.isLeagueSubscribed = isLeagueSubscribed;

                        // Add match if it meets criteria
                        const shouldAdd = !showOnlyFavorites || matchData.isFavouritedByTeam ||
                            matchData.isFavouritedByLeague || matchData.isMatchSubscribed ||
                            matchData.isLocalTeamSubscribed || matchData.isVisitorTeamSubscribed ||
                            matchData.isLeagueSubscribed;

                        if (shouldAdd) listData.push(matchData);
                    }
                }
            }

            // Optimized sort
            listData.sort((a, b) => {
                if (a.isFavouritedByTeam !== b.isFavouritedByTeam)
                    return a.isFavouritedByTeam ? -1 : 1;
                if (a.isFavouritedByTeam)
                    return a.favoriteTeamIndex - b.favoriteTeamIndex;

                if (a.isFavouritedByLeague !== b.isFavouritedByLeague)
                    return a.isFavouritedByLeague ? -1 : 1;
                if (a.isFavouritedByLeague)
                    return a.favoriteLeagueIndex - b.favoriteLeagueIndex;

                if (userCountry && a.country !== b.country)
                    return a.country === userCountry ? -1 : 1;

                if (a.fixedIndex !== b.fixedIndex)
                    return a.fixedIndex ? -1 : 1;
                if (a.fixedIndex)
                    return a.foundFixedIndex - b.foundFixedIndex;

                return a.localizedCountry.localeCompare(b.localizedCountry);
            });

            // Add headers using reduce
            const finalList = listData.reduce((acc, match, index) => {
                if (index === 0 || match.headerId !== listData[index - 1].headerId) {
                    const header = {
                        country: match.country,
                        localizedCountry: match.localizedCountry,
                        league: match.league,
                        title: hiddenTitlesSet.has(match.country) ?
                            match.league :
                            `${match.localizedCountry} - ${match.league}`,
                        type: HEADER_TYPE,
                        style: { height: HEADER_HEIGHT },
                        isFavorite: match.isFavouritedByTeam || match.isFavouritedByLeague,
                        isFavoriteTeam: match.isFavouritedByTeam,
                        isFavoriteLeague: match.isFavouritedByLeague,
                        key: match.leagueKey,
                        accessibility: { focusable: true, allowTabFocus: true }
                    };
                    acc.push(header);
                }
                acc.push(match);
                return acc;
            }, []);

            return {
                listData: finalList,
                foundUnfiltered: listData.length > 0
            };
        },


        prepareHomeFeedData : function(options) {
            // Destructure options
            const {
                enabledFeatures,
                responseData,
                fixedRegions,
                translations,
                favoriteTeams,
                favoriteLeagues,
                locale,
                localeFull,
                userRegionLocale,
                defaultTranslations,
                availableNewsLocales,
                newsLocaleRemap
            } = options;

            // Set up localization
            Localizer.LocalizedTranslations = translations;
            Localizer.DefaultTranslations = defaultTranslations;

            // Constants
            const ITEM_HEIGHT = 50;
            const FEATURED_ITEM_HEIGHT = 70;
            const HEADER_HEIGHT = 40;
            const COUNTRY_TYPE = 1;
            const HEADER_TYPE = 2;
            const FEATURE_TYPE = 3;
            const TEAM_TYPE = 4;
            const LEAGUE_TYPE = 5;
            const AGENDA_TYPE = 6;
            const NO_TEAMS_TYPE = 7;
            const TEAMS_HEADER_TYPE = 8;
            const LEAGUES_HEADER_TYPE = 9;
            const NO_LEAGUES_TYPE = 10;
            const FEATURED_MATCHES_TYPE = 11;

            // Common accessibility object
            const ACCESSIBLE = { focusable: true, allowTabFocus: true };

            const listData = [];

            // Agenda item
            listData.push({
                title: Localizer.getString("agenda"),
                image: "agendaitem.png",
                type: AGENDA_TYPE,
                accessibility: ACCESSIBLE,
                style: { height: ITEM_HEIGHT }
            });

            if (responseData && responseData.match_suggestions) {
                var header = {"title":Localizer.getString("popular_matches"),"type":2,"style":{"height":0}};
                listData.push(header);
                var item = {
                    type:FEATURED_MATCHES_TYPE,
                    matchSuggestions : responseData.match_suggestions,
                    item: "suggestions",
                    style: {height: 80}
                };
                listData.push(item);
            }

            // News section
            if (enabledFeatures.news) {
                const tempLocale = newsLocaleRemap[locale] || locale;
                if (availableNewsLocales.includes(tempLocale) ||
                    (localeFull && availableNewsLocales.includes(localeFull))) {
                    listData.push({
                        title: Localizer.getString("news"),
                        image: "newsitem.png",
                        type: FEATURE_TYPE,
                        style: { height: ITEM_HEIGHT },
                        accessibility: ACCESSIBLE,
                        item: "news"
                    });
                }
            }

            // Remove ads section
            if (responseData?.showRemoveAds) {
                listData.push({
                    title: Localizer.getString("remove_ads"),
                    image: "noadsitem.png",
                    type: FEATURE_TYPE,
                    accessibility: ACCESSIBLE,
                    style: { height: ITEM_HEIGHT },
                    item: "news"
                });
            }

            // Favorite teams section
            listData.push({
                title: Localizer.getString("favoriteteams"),
                type: TEAMS_HEADER_TYPE,
                accessibility: ACCESSIBLE,
                style: { height: HEADER_HEIGHT }
            });

            const favoriteTeamKeys = Object.keys(favoriteTeams);
            if (favoriteTeamKeys.length) {
                const tempTeams = favoriteTeamKeys
                    .map(key => ({ ...favoriteTeams[key], key }))
                    .sort((a, b) => (a.index ?? Infinity) - (b.index ?? Infinity));

                tempTeams.forEach(team => {
                    listData.push({
                        name: team.name,
                        id: team.key,
                        accessibility: ACCESSIBLE,
                        is_live: responseData?.livestats?.teams.includes(team.key) ?? false,
                        type: TEAM_TYPE,
                        style: { height: ITEM_HEIGHT }
                    });
                });
            } else {
                listData.push({
                    type: NO_TEAMS_TYPE,
                    style: { height: ITEM_HEIGHT }
                });
            }

            // Favorite leagues section
            listData.push({
                title: Localizer.getString("favoriteleagues"),
                type: LEAGUES_HEADER_TYPE,
                accessibility: ACCESSIBLE,
                style: { height: HEADER_HEIGHT }
            });

            const favoriteLeagueKeys = Object.keys(favoriteLeagues);
            if (favoriteLeagueKeys.length) {
                const tempLeagues = favoriteLeagueKeys
                    .map(key => ({ ...favoriteLeagues[key], key }))
                    .sort((a, b) => (a.index ?? Infinity) - (b.index ?? Infinity));

                tempLeagues.forEach(league => {
                    listData.push({
                        name: league.name,
                        country: league.country,
                        key: league.key,
                        accessibility: ACCESSIBLE,
                        is_live: responseData?.livestats?.leagues[league.key] != null ?? false,
                        type: LEAGUE_TYPE,
                        style: { height: ITEM_HEIGHT }
                    });
                });
            } else {
                listData.push({
                    type: NO_LEAGUES_TYPE,
                    style: { height: ITEM_HEIGHT }
                });
            }

            // Entertainment section
            if (enabledFeatures.entertainment) {
                listData.push({
                    title: Localizer.getString("entertainment"),
                    type: HEADER_TYPE,
                    style: { height: HEADER_HEIGHT }
                });

                const entertainmentItems = [
                    {
                        item: "fifaranking",
                        title: Localizer.getString("world_ranking_list"),
                        image: "ranking.png"
                    },
                    {
                        item: "playervalues",
                        title: Localizer.getString("player_market_values"),
                        image: "playermarketvalue.png"
                    }
                ];

                if (enabledFeatures.forum) {
                    entertainmentItems.push({
                        item: "forumindex",
                        title: Localizer.getString("forum"),
                        image: "praten.png"
                    });
                }

                if (enabledFeatures.userPredictions) {
                    entertainmentItems.push({
                        item: "topvoters",
                        title: Localizer.getString("top_voters"),
                        image: "topvoters.png"
                    });
                }

                entertainmentItems.forEach(item => {
                    listData.push({
                        ...item,
                        type: FEATURE_TYPE,
                        accessibility: ACCESSIBLE,
                        style: { height: ITEM_HEIGHT }
                    });
                });
            }

            // Countries/Regions section
            listData.push({
                title: Localizer.getString("International"),
                type: HEADER_TYPE,
                style: { height: HEADER_HEIGHT }
            });

            const hiddenCountries = new Set(["World Cup", "World Cup Qualifications", "Eurocups", "England", "Spain", "France"]);
            const regions = responseData.countries
                .filter(country => enabledFeatures.showHiddenLeagues || !hiddenCountries.has(country.country))
                .map(country => ({
                    ...country,
                    type: COUNTRY_TYPE,
                    isFixed: fixedRegions.includes(country.country),
                    foundFixedIndex: fixedRegions.indexOf(country.country),
                    countryLocalized: CountryUtil.getLocalizedCountryByName(country.country, locale),
                    accessibility: ACCESSIBLE,
                    style: { height: ITEM_HEIGHT }
                }))
                .sort((a, b) =>
                    a.isFixed !== b.isFixed ? (a.isFixed ? -1 : 1) :
                        a.isFixed ? a.foundFixedIndex - b.foundFixedIndex :
                            a.countryLocalized.localeCompare(b.countryLocalized)
                );

            listData.push(...regions);

            // Add Regions header before first non-fixed country
            const firstNonFixedIndex = listData.findIndex(item => item.type === COUNTRY_TYPE && !item.isFixed);
            if (firstNonFixedIndex !== -1) {
                listData.splice(firstNonFixedIndex, 0, {
                    title: Localizer.getString("Regions"),
                    type: HEADER_TYPE,
                    accessibility: ACCESSIBLE,
                    style: { height: HEADER_HEIGHT }
                });
            }

            return listData;
        },

    prepareFixtureData: function (options) {

        console.log("prepare fixture data mrec",options);
        // Constants for clarity and maintainability
        const MATCH_HEIGHT_LARGE = 60;
        const MATCH_HEIGHT_TYPE3 = 70;
        const MATCH_HEIGHT_DEFAULT = 55;
        const HEADER_HEIGHT = 40;
        const MATCH_TYPE = 1;
        const HEADER_TYPE = 2;
        const AD_TYPE = 3;
        // Input validation
        if (!options || !Array.isArray(options.responseData?.fixtures)) {
            throw new Error("Invalid or missing fixture data");
        }

        // Destructure options for cleaner access
        const {
            translations,
            defaultTranslations,
            responseData: {fixtures},
            dontSelectByCurrentDate = false,
            fontSize,
            listType
        } = options;

        // Set up localization
        Localizer.LocalizedTranslations = translations;
        Localizer.DefaultTranslations = defaultTranslations;

        // Determine match height
        const isLargeFont = fontSize === "xlarge" || fontSize === "large";
        const matchHeight = listType === "3" ? MATCH_HEIGHT_TYPE3 :
            isLargeFont ? MATCH_HEIGHT_LARGE : MATCH_HEIGHT_DEFAULT;

        // Current time at midnight
        const currentTc = (() => {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            return now.getTime();
        })();

        // Process and sort fixtures in one go
        const sortedMatches = fixtures
            .map(match => ({
                ...match,
                type: MATCH_TYPE,
                dayTimecodeLocalized: MatchUtil.getLocalizedDayNoUTCTimecode(match),
                timecode: MatchUtil.getLocalizedTimecode(match),
                country: match.filegroup,
                league: match.leaguename,
                headerId: HEADER_TYPE,
                style: {height: matchHeight}
            }))
            .sort((a, b) => {
                if (a.dayTimecodeLocalized === b.dayTimecodeLocalized) {
                    if (a.stageName && b.stageName && a.stageName !== b.stageName) {
                        return a.stageName.localeCompare(b.stageName);
                    }
                    return a.timecode - b.timecode || a.localteam.localeCompare(b.localteam);
                }
                return a.timecode - b.timecode || a.localteam.localeCompare(b.localteam);
            });

        // Build final list with headers in a single pass
        const finalList = [];
        let prevDay = null;
        let prevStageName = null;
        let selectedIndex = -1;
        let foundSelected = false;
        let hasDifferentStages = sortedMatches.some((m, i) =>
            i > 0 && m.dayTimecodeLocalized === sortedMatches[i - 1].dayTimecodeLocalized &&
            m.stageName && sortedMatches[i - 1].stageName &&
            m.stageName !== sortedMatches[i - 1].stageName
        );

        for (const match of sortedMatches) {
            const needsHeader = prevDay !== match.dayTimecodeLocalized ||
                (hasDifferentStages && match.stageName && prevStageName !== match.stageName);

            if (needsHeader) {
                const header = {
                    week: match.week,
                    date: match.date,
                    time: match.time,
                    stage: match.stageName,
                    round: match.stageRound,
                    dayTimecodeLocalized: match.dayTimecodeLocalized,
                    style: {height: HEADER_HEIGHT},
                    type: HEADER_TYPE
                };

                // Selection logic
                if (!dontSelectByCurrentDate && !foundSelected) {
                    if (MatchUtil.isPlaying(match)) {
                        header.style.selected = true;
                        foundSelected = true;
                        selectedIndex = finalList.length;
                    } else if (currentTc <= match.dayTimecodeLocalized) {
                        header.style.selected = true;
                        foundSelected = true;
                        selectedIndex = finalList.length;
                    }
                }

                finalList.push(header);
                prevStageName = match.stageName;
            }

            finalList.push(match);
            prevDay = match.dayTimecodeLocalized;
        }

        // Default selection if none found
        if (!foundSelected && finalList.length > 0) {
            finalList[finalList.length - 1].style.selected = true;
            selectedIndex = finalList.length - 1;
        }

        if (options.mrecAdData) {
            console.log("add mrec to list")
            if (selectedIndex >= 0 && finalList.length > selectedIndex + 7) {
                const adItem = {
                    type: AD_TYPE,
                    style: {height: 250},
                    content: 'mediumRectangle',  // Identifier for rendering logic
                    html : options.mrecAdData
                };
                finalList.splice(selectedIndex +7, 0, adItem);
            }
        }

        return finalList;
    },

    prepareTeamFixtureData : function(options){

        console.log("prepare team fixture data",options);

        Localizer.LocalizedTranslations = options.translations;
        Localizer.DefaultTranslations = options.defaultTranslations;

        var data = options.responseData;
        var fontSize = options.fontSize;
        var listType = options.listType;

        if (fontSize == "xlarge" || fontSize == "large") {
            var itemHeight = 60;
        }
        if (options.listType == "3"){
            itemHeight = 70;
        }
        else {
            itemHeight = 55;
        }

        if (data.isProcessedByProcessor) return data;
        data.isProcessedByProcessor = true;

        var listData = [];
        var match;
        var headerId = 2;
        var currentTc = new Date();
        currentTc.setHours(0,0,0,0,0);
        currentTc = currentTc.getTime();

        var inTheFuture = [];
        var inThePast = [];
        var nowPlaying = null;
        var todayPlaying = null;
        var tomorrowPlaying = null;
        var usedIds = [];

        if (data.fixtures) {
            for (var i = 0; i < data.fixtures.length; i++) {
                if (usedIds.indexOf(data.fixtures[i].id) != -1) continue;
                usedIds.push(data.fixtures[i].id);

                match = data.fixtures[i];

                match.type = 1;
                match.dayTimecodeLocalized = MatchUtil.getLocalizedDayNoUTCTimecode(match);
                match.dayTimecodeLocalized = Math.floor(match.dayTimecodeLocalized / 1000) * 1000
                match.timecode = MatchUtil.getLocalizedTimecode(match);

                match.country = match.filegroup;
                match.league = match.leaguename;
                match.headerId = headerId;
                match.style = {"height": itemHeight};

                // console.log(new Date(match.dayTimecodeLocalized),new Date(currentTc))
                // console.log(match.dayTimecodeLocalized,currentTc)

                if (match.status != null && (!isNaN(match.status) || match.status == "HT" || match.status == "P") || match.status == "Break") {
                    nowPlaying = match;
                } else if (match.dayTimecodeLocalized == currentTc) {
                    if (match.status != null && match.status == "FT" || match.status == "AET" || match.status == "Pen." || match.status == "Aban.") {
                        match.isfinished = true;
                    }
                    todayPlaying = match;
                } else if (match.dayTimecodeLocalized < currentTc) {
                    inThePast.push(match);
                } else {
                    inTheFuture.push(match);
                }


                listData.push(match);
            }
        }

        inTheFuture.sort(function(a, b) {
            if (a.timecode > b.timecode){
                return 1;
            }
            else if (a.timecode < b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        inThePast.sort(function(a, b) {
            if (a.timecode > b.timecode){
                return 1;
            }
            else if (a.timecode < b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        inThePast.reverse();

        data.futureMatches = inTheFuture;
        data.pastMatches = inThePast;
        data.nowPlaying = nowPlaying;
        data.todayPlaying = todayPlaying;


        if (!data.nowPlaying && !data.todayPlaying && data.futureMatches[0]){
            //data.nextMatch = data.futureMatches[0];
            //data.futureMatches.splice(0,1);
        }

        if (todayPlaying && !todayPlaying.isfinished && inThePast.length > 0) {
            data.lastPlayedMatch = inThePast[0];
        }
        if (!todayPlaying && inThePast.length > 0) {
            data.lastPlayedMatch = inThePast[0];
        }

        var countryTableFound = false;
        if (data.leagues && data.leagues.length > 0) {
            for (var k = 0; k < data.leagues.length; k++) {
                if (data.country != data.leagues[k].country){
                    countryTableFound = true;
                }
            }
        }

        //some table stuff
        var newLeagues = [];

        if (data.leagues && data.leagues.length > 0){
            for (var k=0; k < data.leagues.length;k++) {


                // if (data.country != data.leagues[k].country){
                //     continue;
                // }
                if (data.leagues[k].table && data.leagues[k].table.filegroup == "empty"){
                    continue;
                }

                var newGroups = [];
                var useLeague = false;

                if (!data.leagues[k].table) continue;

                data.leagues[k].isRealCountry = CountryUtil.isRealCountry(data.leagues[k].country) ? 1 : 0;

                console.log("LLLLLL",data.leagues[k]);

                for (var i = 0; i < data.leagues[k].table.groups.length; i++) {

                    var group = data.leagues[k].table.groups[i];

                    if (data.leagues[k].table.groups[i].is_current != 1) continue;

                    var newTeams = [];

                    for (var j = 0; j < group.teams.length; j++) {
                        if (group.teams[j].id_gs == data.id_gs) {
                            console.log("found team",group.teams[j]);
                            newTeams.push(group.teams[j]);
                        }
                    }

                    data.leagues[k].table.groups[i].teams = newTeams;

                    if (newTeams.length) {
                        console.log("yes we have teams in this group");
                        newGroups.push(data.leagues[k].table.groups[i]);
                        useLeague = true;
                    }
                }

                if (useLeague){
                    newLeagues.push(data.leagues[k]);
                }
                data.leagues[k].table.groups = newGroups;
            }
        }

        data.leagues = newLeagues;
        data.leagues.sort((a,b)=>{
            return b.isRealCountry - a.isRealCountry;
        });

        if (data.squad) {
            for (var i = 0; i < data.squad.length; i++) {
                data.squad[i].type = 1;
                data.squad[i].style = {"height": 50};
            }

            data.squad = data.squad.sort(function(a, b) {
                if (a.position == "A" && b.position != "A"){
                    return -1;
                }
                else if (a.position == "M" && b.position != "M"){
                    return -1;
                }
                else if (a.position == "D" && b.position != "D"){
                    return -1;
                }
                else if (a.position == "G" && b.position != "G"){
                    return -1;
                }
                else{
                    return parseInt(b.goals) - parseInt(a.goals);
                }
            });

            var prevPosition = -1;
            for (var i=0; i < data.squad.length;i++){

                if (data.squad.injured != "True" && data.sidelined){
                    for (var j=0; j < data.sidelined.length;j++){
                        if (data.sidelined[j].id == data.squad[i].id){
                            data.squad[i].injuryDescription = data.sidelined[j].description;
                            data.squad[i].injuryFrom = data.sidelined[j].startdate;
                            break;
                        }
                    }
                }

                if (prevPosition != data.squad[i].position){
                    var header = {"type":2,"style":{"height":40}};
                    if (data.squad[i].position == "A"){
                        header.title = Localizer.getString("attackers");
                    }
                    else if (data.squad[i].position == "M"){
                        header.title = Localizer.getString("midfielders");
                    }
                    else if (data.squad[i].position == "G"){
                        header.title = Localizer.getString("goalkeepers");
                    }
                    else if (data.squad[i].position == "D"){
                        header.title = Localizer.getString("defenders");
                    }

                    data.squad.splice(i, 0, header);
                    i++;
                    prevPosition = data.squad[i].position;
                }
            }
        }

        if (data.coach) {

            var header = {"type":2,"style":{"height":40}};
            header.title = Localizer.getString("coach");

            var item = {};
            item.type = 3;
            item.style = {"height": 50};
            item.data = {coach: data.coach, coachid: data.coachid,coachbirthcountry: data.coachbirthcountry};

            if (!data.squad){
                data.squad = [];
            }
            data.squad.splice(0, 0, item);
            data.squad.splice(0, 0, header);
        }

        if (data.transfers){
            var transferData = [];

            if (data.transfers.in && data.transfers.in.length) {
                var header = {"type":2,"style":{"height":40}};
                header.title = Localizer.getString("incoming_transfers");
                transferData.push(header);
                for (var i = 0; i < data.transfers.in.length; i++) {
                    var item = {};
                    item.type = 1;
                    item.style = {"height": 55};
                    data.transfers.in[i].team = data.transfers.in[i].from;
                    item.data = data.transfers.in[i];
                    transferData.push(item);
                }
            }
            if (data.transfers.out && data.transfers.out.length) {
                var header = {"type": 2, "style": {"height": 40}};
                header.title = Localizer.getString("outgoing_transfers");
                transferData.push(header);
                for (var i = 0; i < data.transfers.out.length; i++) {
                    var item = {};
                    item.type = 1;
                    item.style = {"height": 55};
                    data.transfers.out[i].team = data.transfers.out[i].to;
                    item.data = data.transfers.out[i];
                    transferData.push(item);
                }
            }
            data.transfers = transferData;
        }

        if (data.trophies){
            var previousLeague = null;
            for (var i=0; i < data.trophies.length;i++){

                if (previousLeague != data.trophies[i].league){
                    var header = {"type":2,"style":{"height":40}};
                    header.title = data.trophies[i].league;
                    header.country = data.trophies[i].country;
                    data.trophies.splice(i, 0, header);
                    i++;
                    previousLeague = data.trophies[i].league;
                }
                var seasons =data.trophies[i].seasons;
                var splitted =seasons.replaceAll(" ","").split(",");
                data.trophies[i].seasons = splitted;
                data.trophies[i].type = 1;
                data.trophies[i].style = {"height": 60};
            }
        }

        return data;
    },

    preparePlayerData : function(data,translations,defaultTranslations,userLocale){

        // Localizer.LocalizedTranslations = translations;
        // DefaultTranslations = defaultTranslations;
        if (data.isProcessedByProcessor) return data;
        data.isProcessedByProcessor = true;

        data.type = 1;
        data.style = {"height": 55};

        var allStatistics = [];
        if (data.statistics && data.statistics.length){
            allStatistics = allStatistics.concat(data.statistics);
        }
        if (data.statisticscups && data.statisticscups.length){
            allStatistics = allStatistics.concat(data.statisticscups);
        }
        if (data.statisticscupsintl && data.statisticscupsintl.length){
            allStatistics = allStatistics.concat(data.statisticscupsintl);
        }
        if (data.statisticsintl && data.statisticsintl.length){
            allStatistics = allStatistics.concat(data.statisticsintl);
        }
        allStatistics.sort(function(a,b){
            return b.season.localeCompare(a.season);
        })

        if (data.sidelined && data.sidelined.length){
            for (var i=0; i < data.sidelined.length;i++){
                var item = data.sidelined[i];
                item.injurytype = item.type;
                item.type = 1;
                item.style = {"height": 50};
            }
        }

        if (data.trophies){
            var previousLeague = null;
            for (var i=0; i < data.trophies.length;i++){

                if (previousLeague != data.trophies[i].league){
                    var header = {"type":2,"style":{"height":40}};
                    header.title = data.trophies[i].league;
                    header.country = data.trophies[i].country;
                    data.trophies.splice(i, 0, header);
                    i++;
                    previousLeague = data.trophies[i].league;
                }
                var seasons =data.trophies[i].seasons;
                var splitted =seasons.replaceAll(" ","").split(",");
                data.trophies[i].seasons = splitted;
                data.trophies[i].type = 1;
                data.trophies[i].style = {"height": 60};
            }
        }

        if (data.transfers && data.transfers.length) {
            // var header = {"type":2,"style":{"height":40}};
            // header.title = Localizer.getString("incoming_transfers");
            // transferData.push(header);
            for (var i = 0; i < data.transfers.length; i++) {
                data.transfers[i].transferType = data.transfers[i].type;
                data.transfers[i].type = 1;
                data.transfers[i].style = {"height": 55};
                data.transfers[i].team = data.transfers[i].from;
                ////transferData.push(item);
            }
        }

        if (data.careerclubs && data.careerclubs.length) {
            for (var i = 0; i < data.careerclubs.length; i++) {
                data.careerclubs[i].type = 1;
                data.careerclubs[i].style = {"height": 55};
            }
        }

        data.allStatistics = allStatistics;
        return data;
    },

    prepareRankingData : function(data,translations,defaultTranslations,userLocale){

        for (var i=0; i < data.length;i++){
            var item = data[i];
            item.type = 1;
            item.style = {"height": 50};
        }

        return data;
    },

    preparePlayerMarketValuesData : function(data,filterData,selectedPlayerId){

        var filteredData = [];
        for (var i=0; i < data.length;i++){
            if (filterData && filterData.length){
               if (filterData.indexOf(data[i].nationality.toLowerCase()) == -1){
                   continue;
               }
            }
            var item = data[i];
            item.type = 1;
            item.style = {"height": 50};
            filteredData.push(item);

            if (data[i].id == selectedPlayerId){
                item.style.selected = true;
            }
        }

        return filteredData;
    },

    prepareTopVotersData : function(data){
        var result = [];

        if (!data || !data.responseData) return result;

        if (data.responseData.userVotes){
            //add to beginning of array
            data.responseData.userVotes.isMine = true;
            data.responseData.topVoters.unshift(data.responseData.userVotes);
        }

        for (var i=0; i < data.responseData.topVoters.length;i++){
            var item = data.responseData.topVoters[i];
            item.type = 1;
            item.style = {"height": 50};

            if (item.isMine){
                item.style = {"height": 55};
            }

            result.push(item);
        }
        return result;
    },

    prepareUserVotesData : function(data){
        var result = {};
        if (!data) return result;
        var futurelistData = [];
        var pastlistData = [];


        var pastVotes = data.voteResults;
        for (var i=0; i < pastVotes.length; i++){

            var match = pastVotes[i];
            match.type = 1;
            match.style = {"height": 50};
            match.isPast = true;
            match.dayTimecodeLocalized = MatchUtil.getLocalizedDayTimecode(match);
            match.timecode = MatchUtil.getLocalizedTimecode(match);

            match.country = match.filegroup;
            match.league = match.leaguename;
            //match.headerId = headerId;
            pastlistData.push(match);
        }

        pastlistData.sort(function(a, b) {
            if (a.timecode < b.timecode){
                return 1;
            }
            else if (a.timecode > b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        var newListData = [];
        var header,prevWeek;
        var prevHeader = null;
        for (var i=0; i < pastlistData.length;i++){


            if (prevWeek != pastlistData[i].dayTimecodeLocalized){

                header = {
                    "week":pastlistData[i].week,
                    "date":pastlistData[i].date,
                    "time":pastlistData[i].time,
                    "stage":pastlistData[i].stageName,
                    "stageName":pastlistData[i].stageName,
                    "round":pastlistData[i].stageRound,
                    "dayTimecodeLocalized":pastlistData[i].dayTimecodeLocalized,
                    "style" : {"height": 30},
                    "type" : 2
                };

                prevHeader = header;

                newListData.push(header);
            }

            newListData.push(pastlistData[i])
            prevWeek = pastlistData[i].dayTimecodeLocalized;
        }
        pastlistData = newListData;



        var futureVotes = data.voteFuture;
        for (var i=0; i < futureVotes.length; i++){

            var match = futureVotes[i];
            match.type = 1;
            match.style = {"height": 50};

            match.dayTimecodeLocalized = MatchUtil.getLocalizedDayTimecode(match);
            match.timecode = MatchUtil.getLocalizedTimecode(match);

            match.country = match.filegroup;
            match.league = match.leaguename;
            //match.headerId = headerId;
            futurelistData.push(match);
        }

        futurelistData.sort(function(a, b) {
            if (a.timecode > b.timecode){
                return 1;
            }
            else if (a.timecode < b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        var newListData = [];
        var header,prevWeek;
        var prevHeader = null;
        for (var i=0; i < futurelistData.length;i++){


            if (prevWeek != futurelistData[i].dayTimecodeLocalized){

                header = {
                    "week":futurelistData[i].week,
                    "date":futurelistData[i].date,
                    "time":futurelistData[i].time,
                    "stage":futurelistData[i].stageName,
                    "stageName":futurelistData[i].stageName,
                    "round":futurelistData[i].stageRound,
                    "dayTimecodeLocalized":futurelistData[i].dayTimecodeLocalized,
                    "style" : {"height": 30},
                    "type" : 2
                };

                prevHeader = header;

                newListData.push(header);
            }

            newListData.push(futurelistData[i])
            prevWeek = futurelistData[i].dayTimecodeLocalized;
        }

        futurelistData = newListData;

        data.listData = futurelistData;
        data.listData = data.listData.concat(pastlistData);
        ////data.listData.push(pastlistData);

        return data;
    },

    prepareTopTeams : function(data){

        var teams = data.responseData;

        for (var i=0; i < teams.length;i++){
            teams[i].type = 1;
            teams[i].style = {"height": 50};
        }
        //console.log(teams);
        return teams;
    },

    prepareForumIndexData : function(data){
        var prevGroup = null;
        for (var i=0; i < data.length;i++){

            if (data[i].group != prevGroup){
                var header = {"type":2,"style":{"height":40}};
                header.title = data[i].group;
                data.splice(i, 0, header);
                i++;
                prevGroup = data[i].group;
            }

            var item = data[i];
            item.type = 1;
            item.style = {"height": 55};
        }
        return data;
    },

    //binary search for finding nearest top offset element.
    //thanks to chatgtp. it gave this answer right away
    findNearestItem : function(array,target){
        let left = 0;
        let right = array.length - 1;
        var current;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            current = array[mid];

            if (current.style.offset > target) {
                right = mid - 1;
            } else if (current.style.offset < target) {
                left = mid + 1;
            } else {
                return mid;
            }
        }

        // If we didn't find an exact match, return the nearest element
        if (left > 0) {
            const prev = array[left - 1];
            if (target - prev.style.offset < current.style.offset - target) {
                return left - 1;
            }
        }
        return left;
    },
};