import {Control, EventTypes} from "./Control.js";

export class UiControl extends Control {

    init(...args) {
        super.init(...args);
        this.css = {};
        this.style = '';
        this.isRendered = false;
        this.visible = true; //default is visible
        this.enabled = true;
    }

    addControl(c, autoRender) {
        if (this.isInitialized) {
            const page = this.getPage();
            page.initializeControl(c, this, false, this.controls.length);
            if (autoRender) {
                this.render(c);
            }
        }
        return super.addControl(c);
    }

    addControlAt(c, index, autoRender) {
        if (!this.controls[index]) {
            return this.addControl(c, autoRender);
        }
        if (this.isInitialized) {
            const page = this.getPage();
            page.initializeControl(c, this, false, index);
            if (autoRender) {
                this.render(c, index);
            }
        }
        return super.addControlAt(c, index);
    }

    unload() {
        this.cachedElement = undefined;
    }

    remove() {
        this.getPage().removeControl(this);
    }

    clear() {
        const page = this.getPage();
        if (page) {
            page.clearControl(this);
            this.controls = [];
        }
    }

    getFirstUiControl() {
        if (!this.markAsNonRender) {
            return this;
        }
        return this.parent?.getFirstUiControl() || null;
    }

};

/*
 * @return object jquery dom writer
 */
UiControl.prototype.getDomWriter = function () {
    if (!this.cachedElement) {
        this.cachedElement = jQuery(`#${this.getClientId()}`);
    }
    return this.cachedElement;
};

/**
 * @return string tag name of control
 * overwrite this function to use your own tags. ie <div> <a> <p> <input> <label>
 */
UiControl.prototype.getTagName = function () {
    return '';
};

/**
 * Computes widht,height and left right offsets
 *
 * @return {Object} of dimensions
 */
UiControl.prototype.getDimensions = function () {
    const $el = this.getDomWriter();
    return {
        height: $el.height(),
        width: $el.width(),
        offset: $el.offset(),
        position: $el.position()
    };
};

/**
 * sets client id used to reference dom node. This method is auto called by the page
 * @param {String} cid
 */
UiControl.prototype.setClientId = function (cid) {
    this.clientId = cid;
};

/**
 * @return {String} reference to the dom node id
 */
UiControl.prototype.getClientId = function () {
    return this.clientId;
};

/**
 * Triggers an event which will notify all listeners
 * Optionally data can be send along.
 *
 * @param {String} name of the event to be triggered
 * @param {mixed} data
 */
UiControl.prototype.triggerEvent = function (name, data) {
    if (this.getDomEventTypes().indexOf(name) !== -1) {
        if (this.isRendered) {
            this.getDomWriter().trigger(name, data);
        }
    } else {
        jQuery(this).trigger(name, data);
    }
};

/**
 * Composes attributes for usuage in html tags.
 * @return {Object} of attributes
 */
UiControl.prototype.getAttributes = function () {
    var attributes = this.attributes || {};

    attributes['id'] = this.getClientId();
    attributes['style'] = this.getStyle() || '';

    if (this.getCss()) {
        var css = "";
        for (var prop in this.getCss()) {
            css += prop + ':' + this.getCss()[prop] + ';';
        }
        attributes['style'] += css;
    }

    if (this.cssClass && this.cssClass.length > 0) {
        attributes['class'] = this.getCssClass().join(' ');
    }

    return attributes;
};

/**
 * sets attribute
 * depending on the type of key we set prop or attr
 *
 * see http://blog.jquery.com/ for more information
 * @param {String} key
 * @param {String} value
 * @return {this}
 */
UiControl.prototype.setAttribute = function (key, value) {
    if (this.isRendered) {
        this.getDomWriter().prop(key, value);
    }
    this.attributes = this.attributes || {};
    this.attributes[key] = value;
    return this;
};

/**
 * @String key of the attribute which should be removed
 */
UiControl.prototype.removeAttribute = function (key) {
    if (this.isRendered) {
        this.getDomWriter().removeProp(key);
    }
    if (this.attributes?.[key]) {
        delete this.attributes[key];
    }
    return this;
};

/**
 * @param {String} key of the attribute
 * @return {String} attribute by key
 */
UiControl.prototype.getAttribute = function (key) {
    if (!this.attributes) {
        return null;
    }
    return this.attributes[key];
};

/**
 * NOTE: use setCss() instead to directly apply it to dom
 * @param {String} style of this control. by css definitions
 * @return {this}
 */
UiControl.prototype.setStyle = function (style) {
    if (this.isRendered){
        console.warn("setting style on rendered control wont immediately apply. Use setCss() instead or invalidate control");
    }
    this.style = style;
    return this;
};

/**
 * @return {String}
 */
UiControl.prototype.getStyle = function () {
    return this.style;
};

/**
 * adds css style in object key value style.
 * @param {Object} css example {width:'100px',left:0};
 */
UiControl.prototype.addCss = function (css) {
    this.css = { ...this.css, ...css };
};

/**
 * adds css style and instantly apply it to dom if rendered
 *
 * @param {Object} css
 */
UiControl.prototype.setCss = function (css) {
    this.addCss(css);
    if (this.isRendered) {
        this.getDomWriter().css(this.css);
    }

    return this;
};

/**
 * @return {Object} of css properties in key value format
 */
UiControl.prototype.getCss = function () {
    return this.css;
};

/**
 * @return {String} value of css property
 */
UiControl.prototype.getStyleProperty = function (prop) {
    return this.css[prop] || this.getDomWriter().css(prop);
};

/**
 * @return {Array} of currently added css classes
 */
UiControl.prototype.getCssClass = function () {
    if (!this.cssClass) {
        this.cssClass = [];
    }
    return this.cssClass;
};

/**
 * Adds css class. this will be instantly applied to dom if rendered
 *
 * @param {String} css name of css class
 */
UiControl.prototype.addCssClass = function (css) {
    if (!this.cssClass) {
        this.cssClass = [];
    }

    if (!this.hasCssClass(css)) {
        this.cssClass.push(css);

        if (this.isRendered) {
            this.getDomWriter().addClass(css);
        }
    }

    return this;
};

/**
 * removes css class. this will be instantly applied to dom
 *
 * @param {String} css name of css class
 */
UiControl.prototype.removeCssClass = function (css) {
    if (!this.cssClass) {
        return this;
    }

    var indexOf = this.cssClass.indexOf(css);

    if (indexOf != -1) {
        this.cssClass.splice(indexOf, 1);

        if (this.isRendered) {
            this.getDomWriter().removeClass(css);
        }
    }

    return this;
};

/**
 * Switch the old Css class with a new one
 *
 * @param {String} oldClass Old CSS class to replace
 * @param {String} newClass New CSS class to add
 */
UiControl.prototype.switchCssClass = function (oldClass, newClass) {
    this.removeCssClass(oldClass);
    this.addCssClass(newClass);
    return this;
};

/**
 * @return {boolean}
 */
UiControl.prototype.hasCssClass = function (search) {
    return this.isRendered ? this.getDomWriter().hasClass(search) : this.getCssClass().includes(search);
};

/**
 * return string representation of html attributes. used by renderer
 *
 * @return {String}
 */
UiControl.prototype.getHtmlAttributes = function () {
    const attributes = this.getAttributes();
    return Object.entries(attributes)
        .filter(([_, value]) => value !== undefined && value !== 'undefined')
        .map(([key, value]) => `${key}="${value}"`)
        .join(' ');
};

/**
 * makes control visible or invisible.
 *
 * @param {boolean} v true when visible
 * @param {String} speed animation speed of hiding/ showing
 * @param {String} type of animation
 * @param {Function} callback when finished
 */
UiControl.prototype.setVisible = function (v, speed, type, callback) {
    this.triggerEvent('onSetVisible', v); //trigger this first to prevent flickering
    this.visible = v;
    var ignoreDirectAction = false;
    if (v) {
        if (this.isRendered) {
            if (type === 'fadeIn') {
                this.getDomWriter().fadeIn(speed, () => {
                    this.setCss({'display': ''});
                    if (callback) {
                        callback();
                    }
                });
                ignoreDirectAction = true;
            } else {
                this.getDomWriter().show(speed, () => {
                    this.setCss({'display': ''});
                    if (callback) {
                        callback();
                    }
                });
            }
        }

        if (!ignoreDirectAction) {
            this.setCss({'display': ''});
        }
    } else {
        if (this.isRendered) {
            if (type === 'fadeOut') {
                ignoreDirectAction = true;
                this.getDomWriter().fadeOut(speed, () => {
                    this.setCss({'display': 'none'});
                    if (callback) {
                        callback();
                    }
                });
            } else {
                this.getDomWriter().hide(speed, () => {
                    this.setCss({'display': 'none'});
                    if (callback) {
                        callback();
                    }
                });
            }
        }
        if (!ignoreDirectAction) {
            this.setCss({'display': 'none'});
        }

    }

    return this;
};

/*
 * @return bool true when visible
 */
UiControl.prototype.getVisible = function () {
    if (this.isRendered) {
        return this.getDomWriter().is(":visible");
    } else {
        return this.visible; //note this only works on controls which are directly made invisble not for their childs
    }
};

/**
 * register all binded events
 */
UiControl.prototype.registerEvents = function () {
    if (!this.binds?.length) return;
    this.binds.forEach(({ name, func, data, type }) => {
        if (type === EventTypes.DOM_EVENT) {
            this.getDomWriter().bind(name, data, func);
        } else if (type === EventTypes.CUSTOM_EVENT && this.getPage().isRerendering) {
            jQuery(this).bind(name, data, func);
        }
    });
};

/**
 * unregister all binded events.
 * note we dont touch the bind array, cause we might rebind it at a later moment. ie rerender
 * used by framework
 */
UiControl.prototype.unregisterEvents = function () {
    if (!this.binds?.length) return;
    this.getDomWriter().unbind();
    jQuery(this).unbind();

};

/**
 * sets control enabled /disabled
 * we basicly add a css class. so, make sure your css file contains this css classes
 * TODO this is not nice, can it be done otherwise?
 *
 * @param {boolean} e         True when enabled
 * @param {boolean} recursive Also enable/disable child controls
 */
UiControl.prototype.setEnabled = function (e, recursive) {
    this.triggerEvent('onSetEnabled', e);
    this.enabled = e;
    if (this.isRendered) {
        this.getDomWriter().prop('disabled', !e);
    }
    if (recursive) {
        this.controls.forEach(child => child.setEnabled?.(e, true));
    }
    return this;
};

UiControl.prototype.getEnabled = function () {
    return this.enabled;
};

/**
 * returns html markup string of control + all child controls
 *
 * @return {String}
 */
UiControl.prototype.getHtml = function (markAsRendered, nocache) {
    //if (nocache) {
        this.cachedElement = undefined;
    //}
    const tag = this.getTagName();
    const attrs = this.getHtmlAttributes();
    const controlsHtml = this.controls
        .map(child => (child instanceof Control ? child.getHtml(markAsRendered, true) : child))
        .join('');
    const html = `<${tag} ${attrs}>${controlsHtml}</${tag}>`;
    if (markAsRendered) {
        this.isRendered = true;
    }
    return html;
};
