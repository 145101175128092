import { Control } from "../controls/Control.js";
import { Page } from "../controls/Page.js";
import { UrlManager } from "../util/UrlManager.js";

window.domuuid = 0;

export class Application extends Control {
    constructor(settings) {
        super();
        this.initApplication(settings);
    }

    initApplication(settings) {
        console.log("STARTING APPLICATION", settings);
        if (!jQuery) {
            console.error("JQuery is not found. JQuery required.");
            return;
        }
        this.settings = settings || {};
        this.id = 0; // Increments on each page load
        this.isLoadingPage = false;
        this.delayedSheduledPageLoad = [];
    }

    /**
     * Prepares the application for unload by removing event listeners and cleaning up resources.
     */
    prepareUnload() {
        jQuery(window).on("beforeunload", () => {
            jQuery("*").unbind();
            if (this.activePage) {
                this.activePage.remove();
            }
            if (this.pageTemplate) {
                this.pageTemplate.remove();
            }
            Banana = null; // Assuming Banana is a global; otherwise, this could be removed
        });
    }

    /**
     * Loads the page template specified in the settings.
     */
    loadPageTemplate() {
        const pageTemplateClass = this.settings.pageTemplate;
        if (!pageTemplateClass) {
            console.error("No page template defined. Please define one.");
            return;
        }
        this.pageTemplate = new pageTemplateClass();
    }

    /**
     * Returns the application settings.
     * @returns {Object} The settings object.
     */
    getSettings() {
        return this.settings;
    }

    /**
     * Processes any delayed page load requests.
     */
    processDelayedSheduledPageLoad() {
        if (this.delayedSheduledPageLoad.length > 0) {
            const { page, urlParams, ignoreHistoryParams, cb } = this.delayedSheduledPageLoad.shift();
            this.loadPage(page, urlParams, ignoreHistoryParams, cb);
        }
    }

    /**
     * Loads a page by name, handling URL parameters and history.
     * @param {string} page - The page to load.
     * @param {Object} urlParams - URL parameters to set.
     * @param {boolean} ignoreHistoryParams - Whether to ignore history parameters.
     * @param {Function} cb - Callback after page load.
     * @param {Object} pageParams - Additional parameters for the page.
     */
    loadPage(page, urlParams, ignoreHistoryParams, cb, pageParams) {
        if (this.isLoadingPage) {
            this.delayedSheduledPageLoad.push({ page, urlParams, ignoreHistoryParams, cb });
            console.warn("Cannot load page while loading another page. Delaying.");
            return;
        }
        this.isLoadingPage = true;

        UrlManager.saveModuleHistory("section");
        UrlManager.clearUrl();

        this.loadingWithHistory = !ignoreHistoryParams;
        if (this.loadingWithHistory) {
            const history = UrlManager.getModuleHistory(page);
            for (const key in history) {
                if (history.hasOwnProperty(key)) {
                    UrlManager.registerModule(key);
                    UrlManager.setModule(key, history[key], true);
                }
            }
        }

        if (urlParams) {
            for (const key in urlParams) {
                if (urlParams.hasOwnProperty(key)) {
                    UrlManager.registerModule(key);
                    UrlManager.setModule(key, urlParams[key], true);
                }
            }
        }

        this.run(page, cb, pageParams);
    }

    /**
     * Instantiates a single page instance.
     */
    instantiateSinglePage() {
        if (!this.pageTemplate) {
            this.loadPageTemplate();
        }

        this.page.bind("renderComplete", () => {
            if (this.activePage) {
                this.activePage.removeDom();
                this.activePage.clearProps();
                this.activePage = undefined;
            }
            this.activePage = this.page;
            this.activePage.setVisible(true);
            this.isLoadingPage = false;
            this.processDelayedSheduledPageLoad();
        });

        this.page.setVisible(false);
        this.pageTemplate.addPageControl(this.page);
        this.pageTemplate.setApplication(this);
        this.pageTemplate.run(this);
    }

    /**
     * Runs the specified page.
     * @param {string|Page} page - The page to run (string name or Page instance).
     * @param {Function} cb - Callback after running the page.
     * @param {Object} params - Parameters for the page.
     */
    run(page, cb, params) {
        if (page instanceof Page) {
            this.page = page;
            return this.instantiateSinglePage();
        }

        this.triggerEvent("onPreLoadPage");
        if (!this.activePage) {
            UrlManager.autoRegisterModules();
        }

        if (!this.pageTemplate) {
            this.loadPageTemplate();
        }

        const section = page || UrlManager.getModule("section") || this.settings.defaultPage;
        this.section = section;
        this.pageTemplate.triggerEvent("preLoadPage", section);

        this.registerUrlSection(section);

        if (!this.loadingWithHistory) {
            UrlManager.updateUrl(true);
        }

        const loadPage = () => {
            if (!this.settings.pages[section]) {
                console.error(`Page ${section} not found. Loading default page.`);
                this.isLoadingPage = false;
                return this.loadPage(this.settings.defaultPage);
            }

            const pageClass = new this.settings.pages[section](params);
            this.id++;
            const newPage = pageClass;

            newPage.bind("renderComplete", () => {
                if (this.activePage) {
                    this.activePage.removeDom();
                    this.activePage.clearProps();
                    this.activePage = undefined;
                }
                this.activePage = newPage;
                this.activePage.setVisible(true);
                this.isLoadingPage = false;
                this.processDelayedSheduledPageLoad();
            });

            newPage.setVisible(false);
            this.pageTemplate.addPageControl(newPage);
            this.pageTemplate.setApplication(this);

            if (this.activePage) {
                UrlManager.updateUrl(false);
            } else {
                UrlManager.updateUrl(true);
            }

            this.pageTemplate.run();
            UrlManager.unlistenModule("section");
            UrlManager.listenModule("section", () => {
                this.loadPage(UrlManager.getModule("section"));
            });

            if (cb) cb();
        };

        if (this.activePage) {
            UrlManager.stopChecking();
            this.activePage.remove(true, () => {
                UrlManager.startChecking();
                loadPage();
            });
        } else {
            loadPage();
        }
    }

    /**
     * Registers the section URL and binds a change event to it.
     * @param {string} section - The section to register.
     */
    registerUrlSection(section) {
        UrlManager.registerModule("section", section);
        UrlManager.setModule("section", section, true);
    }
}