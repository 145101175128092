export const AnimatorTopTeamsLeagues = function() {
    // Private variables
    const state = {
        version: "1.0",
        scrollerContainers: [],
        scrollerMethods: [],
        touchStartMethod: null,
        touchEndMethod: null,
        collapser: null,
        tabview: null,
        ignoreEvents: false,
        ignoreEventForIndex: [],
        unloaded: false,
        isTouching: false,
        reducerRatio: 0.5,
        transitionDuration: 100,
        alwaysCollapseOnBackScrolling: true,
        isCollapsed: false,
        lastRecalculateWasCollapsed: null,
        isVirtuallyCollapsed: false,
        lastScrollPositionAfterTouchStart: -1,
        lastYOffsetAfterTouchStart: 0,
        currentTranslateY: 0,
        currentActiveTab: -1,
        cachedTopbarElement: null,
        cachedTabholderElement: null,
        cachedScrollerCollection: null,
        mouseIsDown: false,
        lastUsedScrollerElement: null,
        lastUsedScrollerElementListener: null
    };

    // Utility methods
    const getElementSafe = (selector, cacheKey) => {
        if (!state[cacheKey]) {
            state[cacheKey] = document.querySelector(selector);
        }
        return state[cacheKey];
    };

    // Public methods
    this.addScrollerContainer = (tab) => {
        if (tab) state.scrollerContainers.push(tab);
    };

    this.addCollapser = (c) => {
        state.collapser = c;
    };

    this.addTabView = (tab) => {
        state.tabview = tab;
    };

    this.getTabBar = () => getElementSafe(".topBar", "cachedTopbarElement");
    this.getTabHolder = () => getElementSafe(".tabholder", "cachedTabholderElement");

    this.unload = () => {
        if (state.unloaded) return;
        state.unloaded = true;

        if (state.collapser) {
            state.collapser.setCss({
                "transform": "translate(0px,0px)",
                "transition-duration": "0ms"
            });
        }

        state.scrollerContainers.forEach((container, i) => {
            const $scrollEl = jQuery(`#${container.getClientId()}`);
            if (state.scrollerMethods[i]) {
                $scrollEl.off("scroll", state.scrollerMethods[i]);
            }
        });

        jQuery('body').off({
            'touchstart': state.touchStartMethod,
            'touchend': state.touchEndMethod
        });

        if (state.lastUsedScrollerElement && state.lastUsedScrollerElementListener) {
            state.lastUsedScrollerElement.removeEventListener("scroll", state.lastUsedScrollerElementListener);
        }
    };

    this.initAnimations = () => {
        if (!state.tabview || !FootballManiaConfig?.graphics?.animations) return;

        const topbar = this.getTabBar();
        const tabholder = this.getTabHolder();
        if (!topbar || !tabholder) return;

        const currentTab = state.tabview.currentActiveTab;
        if (!state.cachedScrollerCollection) state.cachedScrollerCollection = [];

        let cachedScroller = state.cachedScrollerCollection[currentTab];
        if (!cachedScroller) {
            const element = document.getElementById(state.scrollerContainers[currentTab]?.getClientId());
            if (!element) return;

            const timeline = new ScrollTimeline({
                source: element,
                axis: "block"
            });

            state.cachedScrollerCollection[currentTab] = { element, timeline };
            cachedScroller = state.cachedScrollerCollection[currentTab];
        }

        [this.tabholderAnimation, this.topbarAnimation].forEach(anim => {
            if (anim) {
                anim.commitStyles();
                anim.cancel();
            }
        });

        const style = window.getComputedStyle(tabholder);
        const matrixY = new DOMMatrix(style.transform).m42;
        const isCollapsed = matrixY === -50;
        const isExpanded = matrixY === 0;

        if (state.lastUsedScrollerElement && state.lastUsedScrollerElementListener) {
            state.lastUsedScrollerElement.removeEventListener("scroll", state.lastUsedScrollerElementListener);
        }

        const scrollHandler = () => {
            if (state.mouseIsDown) return;
            clearTimeout(state.scrollTimeout);
            state.scrollTimeout = setTimeout(() => this.autoCollapseOrOpen(), 200);
        };

        state.lastUsedScrollerElement = cachedScroller.element;
        state.lastUsedScrollerElementListener = scrollHandler;
        cachedScroller.element.addEventListener("scroll", scrollHandler);

        const topOffset = cachedScroller.element.scrollTop;
        const keyframes = {
            transform: ['translateY(0px)', 'translateY(-50px)']
        };
        const options = {
            timeline: cachedScroller.timeline,
            linear: "both",
            fill: isCollapsed ? "backwards" : "forwards",
            rangeStart: `calc(0% + ${isCollapsed ? topOffset - 50 : topOffset}px)`,
            rangeEnd: `calc(0% + ${isCollapsed ? topOffset : topOffset + 50}px)`
        };

        this.tabholderAnimation = tabholder.animate(keyframes, options);
        this.topbarAnimation = topbar.animate(keyframes, options);
    };

    this.autoCollapseOrOpen = () => {
        if (!state.tabview || !FootballManiaConfig?.graphics?.animations) return;

        const scroller = state.scrollerContainers[state.tabview.currentActiveTab];
        if (!scroller) return;

        const tabholder = this.getTabHolder();
        const topbar = this.getTabBar();
        if (!tabholder || !topbar) return;

        const scrollElement = document.getElementById(scroller.getClientId());
        if (!scrollElement) return;

        const style = window.getComputedStyle(tabholder);
        const matrixY = new DOMMatrix(style.transform).m42;
        const topOffset = scrollElement.scrollTop;

        [this.tabholderAnimation, this.topbarAnimation].forEach(anim => {
            if (anim) {
                anim.commitStyles();
                anim.cancel();
            }
        });

        if (topOffset < 25) {
            scrollElement.scroll({ top: 0, behavior: 'smooth' });
            const targetY = 0;
            this.animateToPosition(tabholder, topbar, matrixY, targetY);
        } else if (topOffset < 50) {
            scrollElement.scroll({ top: 50, behavior: 'smooth' });
            const targetY = -50;
            this.animateToPosition(tabholder, topbar, matrixY, targetY);
        } else {
            const targetY = matrixY < -25 ? -50 : 0;
            this.animateToPosition(tabholder, topbar, matrixY, targetY);
        }
    };

    this.animateToPosition = (tabholder, topbar, currentY, targetY) => {
        const keyframes = {
            transform: [`translateY(${currentY}px)`, `translateY(${targetY}px)`]
        };
        const options = {
            duration: 150,
            fill: "forwards"
        };

        this.tabholderAnimation = tabholder.animate(keyframes, options);
        this.topbarAnimation = topbar.animate(keyframes, options);
        this.tabholderAnimation.onfinish = () => this.initAnimations();
    };

    this.run = () => {
        if (!FootballManiaConfig?.graphics?.animations) return;

        state.touchStartMethod = () => {
            state.mouseIsDown = true;
            this.initAnimations();
        };

        state.touchEndMethod = () => {
            state.mouseIsDown = false;
            this.autoCollapseOrOpen();
        };

        jQuery('body').on({
            'touchstart': state.touchStartMethod,
            'touchend': state.touchEndMethod
        });

        this.initAnimations();
    };

    // Stub methods for future implementation
    this.onWindowResize = () => this.recalculate(true);
    this.tabChange = () => {/* Implementation needed */};
    this.recalculateTab = () => {/* Implementation needed */};
    this.recalculate = () => {/* Implementation needed */};
    this.prepare = () => {/* Implementation needed */};
    this.collapseHeader = () => {/* Implementation needed */};
    this.expandHeader = () => {/* Implementation needed */};
    this.isCollapsedCalculated = () => false;
};