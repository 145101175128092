import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Checkbox} from "../../bananaframework/src/controls/Checkbox.js";
import {InputControl} from "../../bananaframework/src/controls/InputControl.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {Favorites} from "../../util/favorites.js";
import {DataProvider} from "../../data/dataprovider.js";
import {CountryUtil} from "../../util/country.js";
import {UrlManager} from "../../bananaframework/src/util/UrlManager.js";

export class Playermarktvaluesfiltermodal extends Modal {

    init(urlIdentifier) {
        super.init(urlIdentifier);
        this.selectedData = [];
        this.allCountries = [];
    }

    createComponents() {
        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 50) + "px;justify-content: flex-start;");
        p.addCssClass("flexVerticalAlign");

        var title = new Panel();
        title.addCssClass("addFavoriteModalTitle");
        title.addControl(Localizer.getString("filter_by_country"));
        p.addControl(title);

        var selectedContainer = new Panel();
        selectedContainer.addCssClass("selectedCountriesContainer");
        var title = new Panel();
        title.setStyle("font-size: 12px;font-weight: bold;margin-bottom: 10px;");
        this.selectedCount = title;

        var resetButton = new Panel();
        resetButton.addControl(Localizer.getString("reset"));
        resetButton.addCssClass("modal-country-reset-button");
        this.resetButton = resetButton;
        this.resetButton.setVisible(false);

        resetButton.bind("click", () => {
            this.selectedData = [];
            searchPh.setVisible(true);
            this.renderCountries(searchResults, this.allCountries);
            this.renderSelected();
            this.updateUrl();
            this.getPage().triggerEvent("countrySelected", {"selected": this.selectedData});

        });

        selectedContainer.addControl(title);
        selectedContainer.addControl(resetButton);
        p.addControl(selectedContainer);

        var search = new Panel();
        search.addCssClass("topBarSearchContainer modalSearchContainer")
        search.setStyle("margin-inline-end:0px;margin-top:10px;position:relative;border: 1px solid #184548;width: 80%;border-radius: 13px;margin-bottom: 16px;");
        var searchInput = new InputControl();
        search.addControl(searchInput);
        searchInput.setStyle("height:40px;text-align: center;")

        var searchPh = new Panel();
        searchPh.addControl(Localizer.getString("menu_search"));
        searchPh.setStyle("font-style: italic;opacity:0.7;position:absolute;top:12px;left:0px;right:0px;bottom:0px;pointer-events:none;")
        search.addControl(searchPh);

        this.searchInput = searchInput;
        p.addControl(search);

        var searchResults = new Panel();
        searchResults.addCssClass("addFavoriteModalSuggestList");
        searchResults.setStyle("height:100%;overflow-y:scroll;width:85%;");
        p.addControl(searchResults)

        this.addControl(p)
        this.setVisible(false);

        setTimeout(() => {
            this.setVisible(true);
            this.reValidateSize();
        }, 50);

        searchInput.bind("click", () => {
            searchPh.setVisible(false);
        });

        searchInput.bind("blur", () => {
            if (!jQuery("#" + searchInput.clientId).val()) {
                searchPh.setVisible(true);
                //this.searchInput.setData("");
            }
        });

        searchInput.bind("keydown", (e) => {
            clearTimeout(this.inputter);
            this.inputter = setTimeout(() => {

                if (!jQuery("#" + searchInput.clientId).val()) {
                    this.renderCountries(searchResults, this.allCountries);
                    this.renderSelected();
                    return;
                }


                if (e.originalEvent.key == "Enter") {
                    jQuery("#" + searchInput.clientId).blur();
                    return;
                }

                var countries = CountryUtil.findCountries(this.allCountries, jQuery("#" + searchInput.clientId).val(), AppInstance.getConfig().userLocale);
                console.log("render ", countries);
                this.renderCountries(searchResults, countries);
                this.renderSelected();

            }, 200);
        });


        DataProvider.getOtherData("topMarketValuedPlayersCountries.json", (response) => {

            if (!response || !response.isOk()) {
                console.log("no goodie", response)
                return;
            }

            let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

            var pushOwnCountry = false;
            if (FootballManiaConfig.userIntlLocale && FootballManiaConfig.userIntlLocale.region) {
                var userCountry = regionNames.of(FootballManiaConfig.userIntlLocale.region);
                var countries = response.getData();
                for (var i = 0; i < countries.length; i++) {
                    console.log(countries[i], userCountry);
                    if (countries[i].toLowerCase() == userCountry.toLowerCase()) {
                        pushOwnCountry = true;
                        console.log("adddd");
                        break;
                    }
                }
            }
            var localizedcountries = CountryUtil.localizeCountries(countries, FootballManiaConfig.userLocale);

            if (pushOwnCountry) {
                var userCountry = CountryUtil.localizeCountries([userCountry], FootballManiaConfig.userLocale)[0];
                userCountry.isOwnCountry = true;
                localizedcountries.unshift(userCountry);
            }
            this.allCountries = localizedcountries;
            this.updateSelectedFromUrl();
            this.renderCountries(searchResults, localizedcountries);
            this.renderSelected();

        });
    }

    addSelected(country) {
        this.selectedData.push(country)
        this.updateUrl();

        console.log("add selected ", this.selectedData);
        //ugly but changing the url is async and we need to wait for it to be done
        setTimeout(() => {
            this.getPage().triggerEvent("countrySelected", {"selected": this.selectedData});
        }, 50);
        console.log("add selected ", this.selectedData);
    }

    removeSelected(country) {
        this.selectedData.splice(this.selectedData.indexOf(country), 1);
        this.updateUrl();

        //ugly but changing the url is async and we need to wait for it to be done
        setTimeout(() => {
            this.getPage().triggerEvent("countrySelected", {"selected": this.selectedData});
        }, 50);
    }

    destroy() {
        super.destroy();
        setTimeout(() => {
            //console.log("u")
            this.updateUrl();
        }, 100);
    }

    updateSelectedFromUrl() {
        var countries = UrlManager.getModule("filter_countries");
        if (countries) {
            countries = atob(countries);
            countries = countries.split(",");
            this.selectedData = countries;
        }
        this.getPage().triggerEvent("countrySelected", {"selected": this.selectedData});
    }

    updateUrl() {
        var string = "";
        for (var i = 0; i < this.selectedData.length; i++) {
            string += this.selectedData[i];
            if (i < this.selectedData.length - 1) string += ",";
        }
        if (string == "") {
            console.log("remove module");
            UrlManager.removeModule("filter_countries");
            UrlManager.updateUrl(true);
            return;
        }
        string = btoa(string);
        var dontAffectHistory = true;
        if (!UrlManager.getModule("filter_countries")) {
            UrlManager.registerModule("filter_countries");
        }
        //module,value,dontUpdateUrl,dontAffectHistory
        UrlManager.setModule("filter_countries", string, false, dontAffectHistory);
    }

    renderSelected() {
        this.selectedCount.clear();

        if (this.selectedData.length) {
            this.selectedCount.addControl(Localizer.getString("selected") + " " + this.selectedData.length, true);
            this.resetButton.setVisible(true);
        } else {
            this.selectedCount.addControl(Localizer.getString("selected") + " " + this.allCountries.length, true);
            this.resetButton.setVisible(false);
        }
    }

    renderCountries(target, countries) {
        target.clear();

        var selected = this.selectedData;
        console.log('render selected', selected);

        for (var i = 0; i < countries.length; i++) {
            var resultP = new Panel();
            resultP.addCssClass("suggestionsItemTitle");
            if (countries[i].isOwnCountry) {
                //resultP.setStyle("padding-bottom:15px;");
            }

            var checkbox = new Checkbox();

            if (selected && selected.indexOf(countries[i].name) != -1) {
                console.log("set data true");
                checkbox.setData(true);
            }

            checkbox.countryData = countries[i];
            checkbox.setStyle("margin-inline-end: 10px;");
            resultP.addControl(checkbox);


            var countryFlag = new Image();
            countryFlag.setStyle("width: 24px; height: 24px; margin-inline-end: 10px;");
            countryFlag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + countries[i].name.replaceAll(" ", "-").toLowerCase() + ".png");
            resultP.addControl(countryFlag);

            var countryPanel = new Panel();
            countryPanel.setStyle("margin-bottom:3px;width:100%;")
            if (countries[i].isOwnCountry) {
                countryPanel.addControl("<b>" + countries[i].localized + "</b>");
            } else {
                countryPanel.addControl(countries[i].localized);
            }
            resultP.addControl(countryPanel);
            target.addControl(resultP);


            ((checkbox, countryPanel) => {
                checkbox.bind("change", () => {
                    console.log("onchange", checkbox.getData(), checkbox.countryData);
                    if (!checkbox.getData()) {
                        this.removeSelected(checkbox.countryData.name);
                        this.renderSelected();
                        return;
                    }
                    this.addSelected(checkbox.countryData.name);
                    this.renderSelected();
                });
                countryPanel.bind("click", () => {
                    checkbox.setData(!checkbox.getData());
                    if (!checkbox.getData()) {
                        this.removeSelected(checkbox.countryData.name);
                        this.renderSelected();
                        return;
                    }
                    this.addSelected(checkbox.countryData.name);
                    this.renderSelected();
                })
            })(checkbox, countryPanel);


        }

        target.invalidateDisplay();
    }

    renderSearchResult(data, target) {
        this.renderTeamItem(data, target, Favorites.getFavoriteTeams()[data.id_gs]);
    }
};
