/**
 * @author Gillis Haasnoot <gillis.haasnoot@gmail.com>
 * @package Banana.Util
 * @summary Various utils
 */

/**
 @class Banana.Util
 @name Banana.Util
 */

/**
 * Natural sort function for comparing strings with numbers, dates, or hex values
 * @param {string} a - First value to compare
 * @param {string} b - Second value to compare
 * @returns {number} - -1 if a < b, 1 if a > b, 0 if equal
 */
export const NaturalSort = (a, b) => {
    const re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?$|^0x[0-9a-f]+$|[0-9]+)/gi;
    const sre = /(^[ ]*|[ ]*$)/g;
    const dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/;
    const hre = /^0x[0-9a-f]+$/i;
    const ore = /^0/;

    const normalize = s => NaturalSort.insensitive ? String(s).toLowerCase() : String(s);
    const x = normalize(a).replace(sre, '') || '';
    const y = normalize(b).replace(sre, '') || '';

    const xN = x.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');
    const yN = y.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');

    const xD = parseInt(x.match(hre), 16) || (xN.length !== 1 && x.match(dre) && Date.parse(x)) || null;
    const yD = parseInt(y.match(hre), 16) || (xD && y.match(dre) && Date.parse(y)) || null;

    if (yD) return xD < yD ? -1 : xD > yD ? 1 : 0;

    const maxLen = Math.max(xN.length, yN.length);
    for (let cLoc = 0; cLoc < maxLen; cLoc++) {
        const xVal = !xN[cLoc]?.match(ore) && parseFloat(xN[cLoc]) || xN[cLoc] || 0;
        const yVal = !yN[cLoc]?.match(ore) && parseFloat(yN[cLoc]) || yN[cLoc] || 0;

        if (isNaN(xVal) !== isNaN(yVal)) return isNaN(xVal) ? 1 : -1;
        if (typeof xVal !== typeof yVal) {
            return String(xVal) < String(yVal) ? -1 : 1;
        }
        if (xVal < yVal) return -1;
        if (xVal > yVal) return 1;
    }
    return 0;
};

/**
 * Cache for namespace lookups
 * @type {Object}
 */
const nameSpaceCache = {};

/**
 * Converts a namespace string to a function reference
 * @param {string} ns - Namespace (e.g., 'window.console.log')
 * @returns {Function|null} - Function reference or null if not found
 */
export const NamespaceToFunction = (ns) => {
    if (nameSpaceCache[ns]) return nameSpaceCache[ns];

    const parts = ns.split('.');
    let fn = window;

    for (const part of parts) {
        fn = fn[part];
        if (!fn) return null;
    }

    nameSpaceCache[ns] = fn;
    return fn;
};

/**
 * Retrieves data from an object by path
 * @param {Object} data - Source object
 * @param {string} path - Dot-separated path (e.g., 'a.b.c')
 * @returns {any|null} - Value at path or null if not found
 */
export const getDataByPath = (data, path) => {
    return path.split('.').reduce((acc, part) => acc?.[part] ?? null, data);
};

/**
 * Clones an object using OWL library
 * @param {any} data - Data to clone
 * @param {boolean} deep - Whether to perform a deep clone
 * @returns {any} - Cloned data
 */
export const Clone = (data, deep) => deep ? owl.deepCopy(data) : owl.clone(data);

/**
 * Finds an object in an array by field value
 * @param {Array} data - Array to search
 * @param {string} field - Field name to check
 * @param {any} value - Value to find
 * @returns {Object|null} - Found object or null
 */
export const FindByField = (data, field, value) => data.find(item => item[field] === value) || null;

/**
 * Combines array items by a field into sub-arrays
 * @param {Array} arr - Array to combine
 * @param {string} field - Field to group by
 * @returns {Array} - Array of grouped arrays
 */
export const CombineArrayByField = (arr, field) => {
    if (!(arr instanceof Array)) {
        log.error('CombineArrayByField - List should be of type Array');
        return arr;
    }
    if (!field) {
        log.error('CombineArrayByField - No field given');
        return arr;
    }

    const grouped = new Map();
    arr.forEach(item => {
        const key = item[field];
        if (!grouped.has(key)) grouped.set(key, []);
        grouped.get(key).push(item);
    });

    return Array.from(grouped.values());
};

/**
 * Copies properties from newdata to olddata recursively
 * @param {Object|Array} newdata - Source data
 * @param {Object|Array} olddata - Target data
 * @param {string} identifier - Key for identifying objects
 * @param {Object} refObj - Reference to parent object
 * @returns {Object|Array} - Updated olddata
 */
export const CopyTo = (newdata, olddata, identifier, refObj) => {
    if (newdata instanceof Array && olddata instanceof Array) {
        const backup = [...olddata];
        olddata.length = 0;

        newdata.forEach((valueA, i) => {
            if (typeof valueA === 'object' && valueA) {
                const idA = valueA[identifier];
                if (idA) {
                    const match = backup.find(valueB => valueB[identifier] === idA);
                    if (match) {
                        olddata.push(match);
                        CopyTo(valueA, match, identifier, { ref: olddata, prop: i });
                    } else {
                        olddata.push(valueA);
                    }
                } else {
                    olddata.push(valueA);
                }
            } else {
                olddata[i] = valueA;
            }
        });
    } else if (newdata instanceof Array) {
        if (!refObj) throw "Unable to assign property to object (missing reference)";
        refObj.ref[refObj.prop] = newdata;
    } else if (typeof newdata === 'object' && newdata !== null) {
        const oldKeys = Object.keys(olddata).filter(key => typeof olddata[key] !== 'function');
        for (const prop in newdata) {
            if (typeof newdata[prop] === 'function') continue;
            const index = oldKeys.indexOf(prop);
            if (index >= 0) oldKeys.splice(index, 1);

            if (typeof newdata[prop] === 'object' && olddata[prop] && newdata[prop] !== null) {
                CopyTo(newdata[prop], olddata[prop], identifier, { ref: olddata, prop });
            } else {
                olddata[prop] = newdata[prop];
            }
        }
        oldKeys.forEach(key => delete olddata[key]);
    }
    return olddata;
};

/**
 * Compares two objects for equality recursively
 * @param {Object} a - First object
 * @param {Object} b - Second object
 * @param {Array} ignores - Properties to ignore
 * @returns {boolean} - True if equal
 */
export const ObjectsAreEqual = (a, b, ignores) =>
    ObjectPropsSameTo(a, b, ignores) && ObjectPropsSameTo(b, a, ignores);

/**
 * Checks if properties of a are in b
 * @param {Object} a - Source object
 * @param {Object} b - Target object
 * @param {Array} ignores - Properties to ignore
 * @returns {boolean} - True if properties match
 */
export const ObjectPropsSameTo = (a, b, ignores = []) => {
    if (!a || !b) return false;

    const ignoreSet = new Set(ignores);
    for (const prop in a) {
        if (typeof a[prop] === 'function') continue;

        if (typeof a[prop] === 'object' && a[prop] !== null && b[prop] !== null) {
            if (typeof b[prop] !== 'object' || !ObjectsAreEqual(a[prop], b[prop], ignores)) {
                return false;
            }
        } else if (['string', 'number', 'boolean'].includes(typeof a[prop])) {
            if (!ignoreSet.has(prop) && b[prop] !== a[prop]) return false;
        }
    }
    return true;
};

/**
 * Sorts an object by its keys
 * @param {Object} obj - Object to sort
 * @param {Function} [sortFunc] - Custom sort function
 * @returns {Object} - New sorted object
 */
export const sortObjectKeys = (obj, sortFunc) => {
    const keys = Object.keys(obj).sort(sortFunc || undefined);
    return Object.fromEntries(keys.map(key => [key, obj[key]]));
};

/**
 * Generates a random unique ID
 * @returns {string} - Random hexadecimal string
 */
export const generateUniqueId = () => (((1 + Math.random()) * 0x1000000000) | 0).toString(16).substring(1);

/**
 * Flattens a tree structure depth-first
 * @param {Object} object - Tree object
 * @param {string} childKey - Key containing children
 * @param {Array} [reversed] - Result array (internal)
 * @returns {Array} - Flattened array
 */
export const flattenTreeDepthFirst = (object, childKey, reversed = []) => {
    if (!object) return reversed;

    if (object[childKey]) {
        object[childKey].forEach(child => flattenTreeDepthFirst(child, childKey, reversed));
    }
    reversed.push(object);
    return reversed;
};

/**
 * Asynchronously iterates over an array in chunks
 * @param {Array} array - Array to iterate
 * @param {Function} cb - Callback per item
 * @param {number} [timeout=0] - Timeout between chunks
 * @param {Function} [completeCb] - Completion callback
 * @param {Function} [completeChunkCb] - Chunk completion callback
 */
export const arrayInteratorAsync = (array, cb, timeout = 0, completeCb, completeChunkCb) => {
    if (typeof cb !== 'function') {
        log.error("No callback specified for async iterator");
        return;
    }

    const chunkSize = Math.max(Math.floor(array.length / 10), 1);
    let i = -1;

    const loop = () => {
        i++;
        if (i >= array.length) {
            completeCb?.();
            return;
        }

        const end = Math.min(i + chunkSize, array.length);
        for (; i < end; i++) {
            cb(array[i], i);
        }

        completeChunkCb?.();
        setTimeout(loop, timeout);
    };

    loop();
};