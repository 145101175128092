import { DataProvider } from "../data/dataprovider.js";
import { DefaultTranslations } from "../translations/default_translations.js";

export const Localizer = {
    DefaultTranslations,
    LocalizedTranslations: null,
    loadedLocale: null,

    /**
     * Retrieves the localized string for the given key.
     * Falls back to default translations or the original string if not found.
     * @param {string} str - The key for the string to localize.
     * @returns {string} The localized string.
     */
    getString(str) {
        const key = str.replace(/\s|'/g, "_");
        if (!this.LocalizedTranslations) {
            console.warn("No localized translations loaded. Using default or original string.");
            return this.DefaultTranslations[key] || str;
        }
        return this.LocalizedTranslations[key] || this.DefaultTranslations[key] || str;
    },

    /**
     * Safely replaces placeholders in the localized string.
     * @param {string} str - The key for the string to localize.
     * @param {Object} replacements - Key-value pairs for replacements (e.g., { name: "John" }).
     * @returns {string} The localized string with replacements.
     */
    getStringSafeReplace(str, replacements) {
        let localized = this.getString(str);
        if (replacements && typeof replacements === "object") {
            for (const [key, value] of Object.entries(replacements)) {
                localized = localized.replace(new RegExp(`{${key}}`, "g"), value);
            }
        }
        return localized;
    },

    /**
     * Checks if a localized string exists for the given key.
     * @param {string} key - The key to check.
     * @returns {boolean} True if the key exists in localized translations.
     */
    hasString(key) {
        return !!(this.LocalizedTranslations && this.LocalizedTranslations[key]);
    },

    /**
     * Loads translations for the specified locale.
     * @param {string} locale - The locale to load (e.g., "en_US").
     * @param {Function} cb - Callback function receiving a boolean success status.
     */
    loadLocale(locale, cb) {
        if (this.loadedLocale === locale) {
            console.log(`Locale ${locale} already loaded.`);
            return cb(true);
        }

        if (!AppInstance.getConfig().allowableLocales.includes(locale)) {
            console.warn(`Locale ${locale} not supported. Falling back to default.`);
            locale = AppInstance.getConfig().defaultLocale;
        }

        DataProvider.getTranslations(locale, (response) => {
            if (response.isOk()) {
                console.log(`Locale ${locale} loaded successfully.`);
                this.LocalizedTranslations = response.getData();
                this.loadedLocale = locale;
                cb(true);
            } else {
                cb(false);
            }
        });
    },

    /**
     * Retrieves the currently loaded locale's translations.
     * @returns {Object|null} The loaded translations or null if none are loaded.
     */
    getLoadedLocale() {
        return this.LocalizedTranslations;
    },
};